import { Component, OnInit, TemplateRef, Input, AfterViewInit } from '@angular/core';
import { NavigationFullScreenComponent } from './../../shared/navigation-full-screen/navigation-full-screen.component';
import { NavbarMinimalComponent } from './../../shared/navbar-minimal/navbar-minimal.component';
import { PerfilComponent } from './../../shared/perfil/perfil.component';
declare let $: any;

@Component({
  selector: 'app-fullscreen',
  templateUrl: './fullscreen.component.html',
  styleUrls: ['./fullscreen.component.css']
})
export class FullscreenComponent implements OnInit, AfterViewInit {

  @Input() title = 'Titulo de la sección';
  @Input() templateBody: TemplateRef<any>;
  ancho = 0;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const self = this;
    $(window).resize(event => {
      self.onResize();
    });
    this.onResize();
  }

  onResize() {
    this.ancho = window.innerWidth;
  }

}
