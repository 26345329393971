import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseService } from './../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class PerfilService extends BaseService {

  constructor(private http: HttpClient) {
    super(http);
  }

  public getPefil(id) {
    const endpoint = '/users/'.concat('' + id).concat('/summary');
    return this.query(endpoint, 'get').pipe(
      map((info: any) => info)
    );
  }

  public getPhoto(id) {
    const endpoint = '/users/' + id;
    return this.query(endpoint, 'get', {}, true);
  }

}
