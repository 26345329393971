import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FarmsService } from 'src/app/services/farms/farms.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-farm-sensors',
  templateUrl: './farm-sensors.component.html',
  styleUrls: ['./farm-sensors.component.css']
})
export class FarmSensorsComponent implements OnInit {

  id: number;
  currentPage;
  sensors: any = {};
  hubs = [];
  loading = true;
  errors = false;

  optionsHubs = {
    text: 'Todos los HUBs',
    value: ''
  };

  constructor(private activatedRoute: ActivatedRoute, private farmsService: FarmsService, private usersService: UsersService) {
    this.activatedRoute.params.subscribe(
      (params: any) => {
        this.usersService.hasPermissionWithMessage('sensors.index.farm.hub');
        if (this.usersService.hasPermission('sensors.index.farm.hub')) {
          this.id = params.id;
          this.selectAllHubs();
          this.getListHubs();
        }
      });
  }

  ngOnInit() {
  }

  getSensors(pos = 1) {
    this.loading = true;
    this.farmsService.getSensors(this.id, this.optionsHubs.value, '' + pos).subscribe(
      data => {
        this.sensors = data;
        this.loading = false;
        this.currentPage = parseInt(data.page, 10);
      },
      error => {
        this.loading = false;
        this.errors = true;
      }
    );
  }

  selectAllHubs() {
    this.loading = true;
    this.farmsService.getSensors(this.id, '', '' + 1).subscribe(
      data => {
        this.sensors = data;
        this.loading = false;
        this.optionsHubs.text = 'Todos los HUBs';
        this.optionsHubs.value = '';
        this.currentPage = parseInt(data.page, 10);
      },
      error => {
        this.loading = false;
        this.errors = true;
      }
    );
  }

  getSensorsByHub(hubId) {
    this.loading = true;
    // tslint:disable-next-line:triple-equals
    const hub = this.hubs.find(element => element.id == hubId);
    this.farmsService.getSensors(this.id, hub.id, '' + 1).subscribe(
      data => {
        this.sensors = data;
        this.loading = false;
        this.optionsHubs.text = hub.name;
        this.optionsHubs.value = hub.id;
        this.currentPage = parseInt(data.page, 10);
      },
      error => {
        this.loading = false;
        this.errors = true;
      }
    );
  }

  getListHubs() {
    this.farmsService.getHubs(this.id).subscribe(
      (data: any) => {
        this.hubs = data.hubs;
      },
      error => {
        console.error(error);
      }
    );
  }

  getLeftPagination() {
    if (this.sensors.page > 1) {
      this.getSensors(this.currentPage - 1);
    }
  }

  getRightPagination() {
    if (this.sensors.page < this.sensors.total_pages) {
      this.getSensors(this.currentPage + 1);
    }
  }

  hasPermission(permission: string) {
    return this.usersService.hasPermission(permission);
  }

}
