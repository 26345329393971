import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FarmsService } from 'src/app/services/farms/farms.service';
import { UsersService } from 'src/app/services/users/users.service';
declare let $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  id: number;
  dataAll;

  data = [];

  constructor(private activatedRoute: ActivatedRoute, private location: Location, private farmsService: FarmsService, private router: Router, 
    private usersService: UsersService) {
    this.activatedRoute.params.subscribe(
      (params: any) => {
        this.usersService.hasPermissionWithMessage('farms.show.measurementStatistics');
        if (this.usersService.hasPermission('farms.show.measurementStatistics')) {
          this.id = params.id;
          this.getAlarms();
        }
      });
  }

  ngOnInit() {
    this.dataAll = this.data;
  }

  ngAfterViewInit() {
    const self = this;
    $(window).resize(event => {
      self.onResize();
    });
    this.onResize();
  }

  onResize() {
    if (window.innerWidth < 767.98) {
      this.data = this.dataAll.slice(0, 1);
    } else if (window.innerWidth < 991.98) {
      this.data = this.dataAll.slice(0, 2);
    } else if (window.innerWidth < 1199.98) {
      this.data = this.dataAll.slice(0, 3);
    } else {
      this.data = this.dataAll;
    }
  }

  goBack() {
    this.location.back();
  }

  goSummary() {{
    this.router.navigate(['/farms', this.id, 'summary']);
  }}

  goAlarms() {{
    this.router.navigate(['/farms', this.id, 'alarms']);
  }}

  getAlarms() {
    this.farmsService.getAlarms(this.id, '' + 5).subscribe(
      (data: any) => {
        if (typeof data !== 'undefined') {
          this.data = data;
          this.dataAll = data;
        }
        else {
          this.data = [];
          this.dataAll = [];
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  hasPermission(permission: string) {
    return this.usersService.hasPermission(permission);
  }

}
