import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Farm } from "./../../../models/farm";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { UsersService } from "src/app/services/users/users.service";
import { FarmsService } from "src/app/services/farms/farms.service";
import Swal from "sweetalert2/dist/sweetalert2";
declare let $: any;

@Component({
  selector: "app-card-farm",
  templateUrl: "./card-farm.component.html",
  styleUrls: ["./card-farm.component.css"],
})
export class CardFarmComponent implements OnInit {
  @Input() farm: Farm;
  @Output() deleted = new EventEmitter<number>();

  loadingDelete = false;

  icons = [
    {
      id: 1,
      name: "humedad",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 11px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-humedad.png",
    },
    {
      id: 2,
      name: "temperatura",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 11px; height: 17px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-temperatura.png",
    },
    {
      id: 3,
      name: "luz",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-luz.png",
    },
    {
      id: 4,
      name: "humedad suelo",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-humedad-suelo.png",
    },
    {
      id: 5,
      name: "radiacion",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-radiacion.png",
    },
    {
      id: 6,
      name: "conductancia",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_08.png",
    },
    {
      id: 7,
      name: "ox_disuelto",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_06.png",
    },
    {
      id: 8,
      name: "ox_temperatura",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_05.png",
    },
    {
      id: 9,
      name: "ox_porcentaje",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_07.png",
    },
    {
      id: 10,
      name: "salinidad",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_04.png",
    },
    {
      id: 11,
      name: "bateria",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_03.png",
    },
    {
      id: 12,
      name: "salinidad",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_04.png",
    },
    {
      id: 13,
      name: "ox_corregido",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_06.png",
    },
  ];

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private userService: UsersService,
    private farmsService: FarmsService
  ) {}

  ngOnInit() {
    $("[data-toggle]").tooltip();
  }

  changeStatus(event) {
    event.preventDefault();
    const status = event.target.checked;
    if (event.target.checked) {
      this.reactive();
    } else {
      $("#exampleModal" + this.farm.id).modal("show");
    }
  }

  preDelete() {
    $("#exampleModal" + this.farm.id).modal("show");
  }

  delete() {
    this.loadingDelete = true;
    this.farmsService.deleteFarm(this.farm.id).subscribe(
      (data) => {
        this.loadingDelete = false;
        $("#exampleModal" + this.farm.id).modal("hide");
        setTimeout(() => {
          this.deleted.emit(this.farm.id);
        }, 1500);
      },
      (error) => {
        this.loadingDelete = false;
        console.error(error);
      }
    );
  }

  desactive() {
    this.loadingDelete = true;
    this.farmsService.deleteFarm(this.farm.id).subscribe(
      (data) => {
        this.loadingDelete = false;
        this.farm.status = 2;
        $("[data-toggle]").tooltip("dispose");
        setTimeout(() => {
          $("[data-toggle]").tooltip();
        }, 300);
        $("#exampleModal" + this.farm.id).modal("hide");
        $("#customSwitch" + this.farm.id).prop("checked", false);
        Swal.fire({
          icon: "success",
          text: "La finca y todas sus configuraciones se desactivaron con éxito.",
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      },
      (error) => {
        this.loadingDelete = false;
        console.error(error);
      }
    );
  }

  reactive() {
    this.farmsService.reactiveFarm(this.farm.id).subscribe(
      (data) => {
        this.farm.status = 1;
        $("[data-toggle]").tooltip("dispose");
        setTimeout(() => {
          $("[data-toggle]").tooltip();
        }, 300);
        $("#customSwitch" + this.farm.id).prop("checked", true);
        Swal.fire({
          icon: "success",
          text: "La finca y todas sus configuraciones se activaron con éxito.",
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  getIcon(id: number = 1) {
    return this.icons.find((elemento) => elemento.id === id) || this.icons[0];
  }

  show(id: number) {
    $("[data-toggle]").tooltip("hide");
    this.userService.token();
    const payload = this.userService.payload();
    if (payload.role === "Notificado") {
      this.router.navigate(["/farms", id, "alarms"]);
    } else {
      this.router.navigate(["/farms", id, "summary"]);
    }
  }

  goEdit() {
    if (this.farm.status === 1) {
      $("[data-toggle]").tooltip("hide");
      this.router.navigate(["/farms", this.farm.id, "edit"]);
    }
  }

  toGrades(num: number) {
    num = Math.abs(num);
    const grades = parseInt("" + num, 10);
    const minutesTemp = (num - grades) * 60;
    const minutes = parseInt("" + minutesTemp, 10);
    const seconds = parseInt("" + (minutesTemp - minutes) * 60, 10);
    return grades + "°" + minutes + "'" + seconds + "''";
  }

  hasPermission(permission: string) {
    return this.userService.hasPermission(permission);
  }
}
