import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { LoginComponent } from './../../login/login.component';

@Component({
  selector: 'app-navigation-horizontal',
  templateUrl: './navigation-horizontal.component.html',
  styleUrls: ['./navigation-horizontal.component.css']
})
export class NavigationHorizontalComponent implements OnInit {

  @Input() templateHeader: TemplateRef<any>;
  @Input() templateBody: TemplateRef<any>;

  constructor() { }

  ngOnInit() {
  }


}
