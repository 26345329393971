import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FarmsService } from './../../services/farms/farms.service';
import { Farm } from './../../models/farm';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users/users.service';
import Swal from 'sweetalert2/dist/sweetalert2';
declare let $: any;

@Component({
  selector: 'app-farms',
  templateUrl: './farms.component.html',
  styleUrls: ['./farms.component.css']
})
export class FarmsComponent implements OnInit, AfterViewInit {

  title = '¿Qué finca quieres gestionar?';
  currentPage;
  farms: Array<Farm> = [];
  data: any = {};
  loading = true;
  noloading = false;
  errors = false;
  ancho;

  constructor(private farmsService: FarmsService, private router: Router, private userService: UsersService) { }

  ngOnInit() {

    this.getFarms();

  }

  ngAfterViewInit() {

    const self = this;
    $(window).resize(event => {
      self.onResize();
    });
    this.onResize();
  }

  onResize() {
    this.ancho = window.innerWidth;
  }

  getFarms(page = 1) {
    this.loading = true && !this.noloading;
    this.farmsService.getFarms('' + page).subscribe(
      data => {
        this.farms = data.data;
        this.data = data;
        this.loading = false;
        this.noloading = false;
        this.currentPage = parseInt(data.page, 10);
      },
      error => {
        this.loading = false;
        this.noloading = false;
        this.errors = true;
        if (error.status === 401) {
          this.router.navigate(['/']);
        }
      }
    );
  }

  getLeftPagination() {
    if (this.data.page > 1) {
      this.getFarms(this.currentPage - 1);
    }
  }

  getRightPagination() {
    if (this.data.page < this.data.total_pages) {
      this.getFarms(this.currentPage + 1);
    }
  }

  removeFromArray(id) {
    this.farms = this.farms.filter((element) => element.id !== id);
    if (this.farms.length === 0 && parseInt(this.data.page, 10) > 1) {
      this.getLeftPagination();
    } else {
      this.noloading = true;
      this.getFarms(this.data.page);
    }
    Swal.fire({
      icon: 'success',
      text: 'La finca ha sido eliminada exitosamente',
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });
  }

  hasPermission(permission: string) {
    return this.userService.hasPermission(permission);
  }

}
