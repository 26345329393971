import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FarmsService } from 'src/app/services/farms/farms.service';
import { UsersService } from 'src/app/services/users/users.service';
declare let $: any;

@Component({
  selector: 'app-farm-hubs',
  templateUrl: './farm-hubs.component.html',
  styleUrls: ['./farm-hubs.component.css']
})
export class FarmHubsComponent implements OnInit {

  id: number;
  currentPage;
  hubs: any = {};
  loading = true;
  errors = false;

  constructor(private activatedRoute: ActivatedRoute, private farmsService: FarmsService, private router: Router, private userService: UsersService) {
    this.activatedRoute.params.subscribe(
      (params: any) => {
        this.userService.hasPermissionWithMessage('hubs.index.farm');
        if (this.userService.hasPermission('hubs.index.farm')) {
          this.id = params.id;
          this.getHubs();
        }
      });
  }

  ngOnInit() {
    $('[data-toggle=tooltip]').tooltip();
  }

  getHubs(pos = 1) {
    this.farmsService.getHubsByFarm(this.id, pos).subscribe(
      data => {
        this.hubs = data;
        this.loading = false;
        this.currentPage = parseInt(data.page, 10);
      },
      error => {
        this.loading = false;
        this.errors = true;
      }
    );
  }

  getLeftPagination() {
    if (this.hubs.page > 1) {
      this.getHubs(this.currentPage - 1);
    }
  }

  getRightPagination() {
    if (this.hubs.page < this.hubs.total_pages) {
      this.getHubs(this.currentPage + 1);
    }
  }

  hasPermission(permission: string) {
    return this.userService.hasPermission(permission);
  }

}
