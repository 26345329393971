import { Component, OnInit } from '@angular/core';
import { NavigationHorizontalComponent } from './../../shared/navigation-horizontal/navigation-horizontal.component';
import { NavbarComponent } from './../../shared/navbar/navbar.component';
import { PerfilComponent } from './../../shared/perfil/perfil.component';

@Component({
  selector: 'app-horizontal',
  templateUrl: './horizontal.component.html',
  styleUrls: ['./horizontal.component.css']
})
export class HorizontalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
