import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FarmsService } from "src/app/services/farms/farms.service";
import { DomSanitizer } from "@angular/platform-browser";
import { NotifyService, Type } from "src/app/services/notify/notify.service";
import { UsersService } from "src/app/services/users/users.service";
declare let $: any;

@Component({
  selector: "app-farm-summary",
  templateUrl: "./farm-summary.component.html",
  styleUrls: ["./farm-summary.component.css"],
})
export class FarmSummaryComponent implements OnInit {
  id: number;

  data: any = {
    name: "",
    hubs: 0,
    sensors: 0,
    creation_date: "2020-01-22",
    lat_coordinate: 19.1571124,
    lon_coordinate: 89.1905,
    last_alert: {
      // alarm_date: '2020-01-30',
      alarm_hour: "5:01 PM",
      device_name: "Sensor 1",
      measurement_name: "humedad del suelo",
      alarm_comment: "",
    },
    measurements: [
      {
        name: "temperature",
        scale: "°",
        icon: 1,
        current_value: 0,
        last_value: 0,
        difference: 0,
      },
    ],
    hubsLoaded: [{ id: 1, name: "Hub 1" }],
  };

  measurements = [];

  icons = [
    {
      id: 1,
      name: "humedad",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 11px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-humedad.png",
    },
    {
      id: 2,
      name: "temperatura",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 11px; height: 17px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-temperatura.png",
    },
    {
      id: 3,
      name: "luz",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-luz.png",
    },
    {
      id: 4,
      name: "humedad suelo",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-humedad-suelo.png",
    },
    {
      id: 5,
      name: "radiacion",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-radiacion.png",
    },
    {
      id: 6,
      name: "conductancia",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_08.png",
    },
    {
      id: 7,
      name: "ox_disuelto",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_06.png",
    },
    {
      id: 8,
      name: "ox_temperatura",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_05.png",
    },
    {
      id: 9,
      name: "ox_porcentaje",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_07.png",
    },
    {
      id: 10,
      name: "salinidad",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_04.png",
    },
    {
      id: 11,
      name: "bateria",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_03.png",
    },
    {
      id: 12,
      name: "salinidad",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_04.png",
    },
    {
      id: 13,
      name: "ox_corregido",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_06.png",
    },
  ];

  frecuencies = [
    { id: 1, text: "Hoy" },
    { id: 2, text: "Ayer" },
    { id: 3, text: "Última semana" },
    { id: 4, text: "Último mes" },
    { id: 5, text: "Últimos seis meses" },
  ];

  optionsHUbs = {
    value: "",
    text: "Todos los hubs",
  };

  optionsFrecuency = {
    value: 5,
    text: "Últimos seis meses",
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private farmsService: FarmsService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private notifyService: NotifyService,
    private usersService: UsersService
  ) {
    this.activatedRoute.params.subscribe((params: any) => {
      this.usersService.hasPermissionWithMessage("farms.show");
      if (this.usersService.hasPermission("farms.show")) {
        this.id = params.id;
        this.getSummaryFarm();
      }
    });
  }

  ngOnInit() {}

  getSummaryFarm() {
    this.farmsService.getSummaryFarm(this.id).subscribe(
      (data) => {
        this.data = data;
        if (this.data.status === 1) {
          // this.getAllMeasurements();
          this.measurements = data.measurements;
          this.optionsHUbs.text = "Todos los hubs";
          this.optionsHUbs.value = "";
          this.updateTooltips();
        } else {
          this.notifyService.notify(Type.danger, "Error", "Acceso denegado");
          this.router.navigate(["/"]);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  updateTooltips() {
    $("[data-toggle]").tooltip("dispose");
    setTimeout(() => {
      $("[data-toggle]").tooltip("update");
    }, 3000);
  }

  getIcon(id: number = 1) {
    return this.icons.find((elemento) => elemento.id === id) || this.icons[0];
  }

  toGrades(num: number) {
    num = Math.abs(num);
    const grades = parseInt("" + num, 10);
    const minutesTemp = (num - grades) * 60;
    const minutes = parseInt("" + minutesTemp, 10);
    const seconds = parseInt("" + (minutesTemp - minutes) * 60, 10);
    return grades + "°" + minutes + "'" + seconds + "''";
  }

  getAllMeasurements() {
    this.farmsService
      .getMeasurements(this.id, "", "" + this.optionsFrecuency.value)
      .subscribe(
        (data: any) => {
          this.measurements = data;
          this.optionsHUbs.text = "Todos los hubs";
          this.optionsHUbs.value = "";
          this.updateTooltips();
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getMeasurementsByFrecuency(id, text) {
    this.farmsService
      .getMeasurements(this.id, this.optionsHUbs.value, "" + id)
      .subscribe(
        (data: any) => {
          this.measurements = data;
          this.optionsFrecuency.text = text;
          this.optionsFrecuency.value = id;
          this.updateTooltips();
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getMeasurementsByHub(idhub, text) {
    this.farmsService
      .getMeasurements(this.id, idhub, "" + this.optionsFrecuency.value)
      .subscribe(
        (data: any) => {
          this.measurements = data;
          this.optionsHUbs.text = text;
          this.optionsHUbs.value = idhub;
          this.updateTooltips();
        },
        (error) => {
          console.error(error);
        }
      );
  }

  showDetails() {
    // @ts-ignore
    window.$("[data-toggle]").tooltip("hide");
    this.router.navigate(["/farms", this.id, "details", "general"]);
  }

  hasPermission(permission: string) {
    return this.usersService.hasPermission(permission);
  }
}
