import { Component, OnInit, Input } from '@angular/core';
import { PerfilService } from 'src/app/services/perfil/perfil.service';

@Component({
  selector: 'app-get-photo-perfil',
  templateUrl: './get-photo-perfil.component.html',
  styleUrls: ['./get-photo-perfil.component.css']
})
export class GetPhotoPerfilComponent implements OnInit {

  @Input() id: any = null;
  @Input() height = '100px';
  @Input() width = '100px';

  image: any;

  constructor(private perfilService: PerfilService) { }

  ngOnInit() {
    this.getPhoto();
  }

  getPhoto() {
    this.perfilService.getPhoto(this.id).subscribe(
      (data: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          this.image = reader.result;
        }, false);
        if (data) {
          reader.readAsDataURL(data);
        }
      },
      error => {
        console.error(error);
      });
  }
}
