import { Component, OnInit } from '@angular/core';
import { FarmsService } from 'src/app/services/farms/farms.service';
import { TimeUnit } from 'chart.js';
import { ActivatedRoute } from '@angular/router';
import { PerfilService } from 'src/app/services/perfil/perfil.service';
import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfFonts from '../../../assets/font/vfs_fonts';
import * as moment from 'moment';
import * as Chart from 'chart.js';
import * as rasterizeHTML from 'rasterizehtml';
import '@babel/polyfill';
import * as ExcelJS from 'exceljs/dist/exceljs.bare.min.js';
import * as fs from 'file-saver';
import { UsersService } from 'src/app/services/users/users.service';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Montserrat: {
    normal: 'Montserrat-Regular.ttf',
    bold: 'Montserrat-Bold.ttf',
    italics: 'Montserrat-Italic.ttf',
    bolditalics: 'Montserrat-BoldItalic.ttf'
  },
  fontello: {
    normal: 'fontello.ttf',
    bold: 'fontello.ttf',
    italics: 'fontello.ttf',
    bolditalics: 'fontello.ttf'
  },
};

@Component({
  selector: 'app-report-generator',
  templateUrl: './report-generator.component.html',
  styleUrls: ['./report-generator.component.css']
})
export class ReportGeneratorComponent implements OnInit {

  id;

  format = 'pdf';
  type = 'farms';
  measurementType = '';
  date = 4;

  measurementsTypes: any[];

  dataReport1 = {
    generadoPor: '',
    duration: '',
    variable: '',
    farmName: '',
    hubs: [],
    graphValues: [],
  };

  dataReport2 = {
    alarms: [],
    textAlarm: '',
    alarmsGenerated: [],
    generadoPor: '',
    duration: '',
    variable: '',
    farmName: '',
    xlsx: {
      alarms: [],
      alarmsGenerated: [],
    }
  };

  frecuencies = [
    { id: 1, text: 'Hoy' },
    { id: 2, text: 'Ayer' },
    { id: 3, text: 'Última semana' },
    { id: 4, text: 'Último mes' },
    { id: 5, text: 'Últimos seis meses' },
  ];

  loadingform = false;

  constructor(private farmsService: FarmsService, private activatedRoute: ActivatedRoute, private perfilService: PerfilService, private usersService: UsersService) {
    this.activatedRoute.params.subscribe((params: any) => {
      this.usersService.hasPermissionWithMessage('reports.generate');
      if (this.usersService.hasPermission('reports.generate')) {
        this.id = params.id;
      }
    });
  }

  ngOnInit() {
    this.getMeauserementsTypes();
  }

  getMeauserementsTypes() {
    this.farmsService.getListMeasurements().subscribe(
      (data: any) => {
        this.measurementsTypes = data;
      },
      error => {
        console.error(error);
      }
    );
  }

  cleanReport() {

    this.dataReport1 = {
      generadoPor: '',
      duration: '',
      variable: '',
      farmName: '',
      hubs: [],
      graphValues: [],
    };

    this.dataReport2 = {
      alarms: [],
      textAlarm: '',
      alarmsGenerated: [],
      generadoPor: '',
      duration: '',
      variable: '',
      farmName: '',
      xlsx: {
        alarms: [],
        alarmsGenerated: [],
      }
    };

  }

  submit() {

    this.cleanReport();

    if (this.format === 'pdf') {
      if (this.type === 'alarms') {
        this.getAlarms();
      }
      if (this.type === 'farms') {
        this.getPerfil();
      }
    }

    if (this.format === 'xlsx') {

      if (this.type === 'alarms') {
        this.getAlarms();
      }
      if (this.type === 'farms') {
        this.getPerfil();
      }

    }

    /*const format = this.format;
    this.loadingform = true;
    this.farmsService.getReport(this.type, this.format, this.measurementType, '' + this.date).subscribe(
      (data: any) => {
        this.loadingform = false;
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'report.' + format;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
      error => {
        this.loadingform = false;
        console.error(error);
      }
    );*/
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

  getAlarms() {

    this.loadingform = true;
    this.dataReport2.alarms = [];
    this.dataReport2.xlsx.alarms = [];

    this.dataReport2.alarms.push([
      { text: 'Estado', style: 'strong', fillColor: '#60813e', color: '#FFFFFF' },
      // { text: 'Id', style: 'strong', fillColor: '#60813e', color: '#FFFFFF' },
      { text: 'Variable', style: 'strong', fillColor: '#60813e', color: '#FFFFFF' },
      { text: 'Dispositivo', style: 'strong', fillColor: '#60813e', color: '#FFFFFF' },
      { text: 'Valor limite', style: 'strong', fillColor: '#60813e', color: '#FFFFFF' },
      { text: 'Regla', style: 'strong', fillColor: '#60813e', color: '#FFFFFF' },
      { text: 'Canal', style: 'strong', fillColor: '#60813e', color: '#FFFFFF' },
      { text: 'Receptor', style: 'strong', fillColor: '#60813e', color: '#FFFFFF' }
    ]);

    // tslint:disable-next-line:no-shadowed-variable
    this.farmsService.report(this.type, '' + this.date, this.id, this.measurementType).subscribe((data: any) => {

      this.dataReport2.xlsx.alarms = data.alarms;
      const alarms2 = data.alarms;
      let activos = 0;
      let inactivos = 0;
      alarms2.forEach((element) => {
        const array = [
          { text: (parseInt(element.status, 10) === 1) ? 'activo' : 'inactivo', fillColor: '#EFEFEF' },
          // { text: element.id, fillColor: '#EFEFEF' },
          { text: element.variable.name, fillColor: '#EFEFEF' },
          { text: element.device.name, fillColor: '#EFEFEF' },
          { text: element.limit_value, fillColor: '#EFEFEF' },
          { text: element.alarm_rule, fillColor: '#EFEFEF' },
          { text: element.alarm_channel, fillColor: '#EFEFEF' },
          { text: element.receptor.name, fillColor: '#EFEFEF' },
        ];
        this.dataReport2.alarms.push(array);
        if (parseInt(element.status, 10) === 1) {
          activos++;
        } else {
          inactivos++;
        }
      });
      if (alarms2.length === 0) {
        this.dataReport2.alarms.push([{ colSpan: 7, text: ['\n', 'No hay alertas creadas\n', '\n'], style: 'center' }, '', '', '', '', '', '']);
      }
      this.dataReport2.textAlarm = 'Total creadas: ' + alarms2.length + ' activas: ' + activos + ' inactivas: ' + inactivos;

      this.dataReport2.xlsx.alarmsGenerated = data.alarmHistorics;
      const alarmsGenerates2 = data.alarmHistorics;
      alarmsGenerates2.forEach((element) => {
        const array = [
          {
            text: [
              {
                text: '' + moment(element.alarm_date).format('DD/MM/YYYY') + '\n'
              }, {
                text: '' + element.alarm_hour + '\n'
              },
              {
                text: '' + element.alarm_channel
              },
            ],
            border: [false, false, true, false],
            borderColor: ['#FFFFFF', '#FFFFFF', '#2c4d31', '#FFFFFF']
          },
          {
            text: [
              { text: '' + element.device_name + ' - ' + element.hub_name + '\n', style: ['strong'] },
              { text: '\n', fontSize: 8 },
              { text: '' + element.alarm_comment ? element.alarm_comment : '', fontSize: 10, style: ['justify'] }
            ],
            alignment: 'justify',
            border: [true, false, false, false],
            borderColor: ['#2c4d31', '#FFFFFF', '#FFFFFF', '#FFFFFF']
          }
        ];
        this.dataReport2.alarmsGenerated.push(array);
        this.dataReport2.alarmsGenerated.push([
          { text: '', border: [false, false, true, false], borderColor: ['#FFFFFF', '#FFFFFF', '#2c4d31', '#FFFFFF'] },
          { text: '', border: [true, false, false, false], borderColor: ['#2c4d31', '#FFFFFF', '#FFFFFF', '#FFFFFF'] }
        ]);
        this.dataReport2.alarmsGenerated.push([
          { text: '', border: [false, false, true, false], borderColor: ['#FFFFFF', '#FFFFFF', '#2c4d31', '#FFFFFF'] },
          { text: '', border: [true, false, false, false], borderColor: ['#2c4d31', '#FFFFFF', '#FFFFFF', '#FFFFFF'] }
        ]);
      });
      if (alarmsGenerates2.length === 0) {
        this.dataReport2.alarmsGenerated.push([
          { text: 'No hay alertas generadas.', border: [false, false, false, false], borderColor: ['#FFFFFF', '#FFFFFF', '#60813e', '#FFFFFF'], colSpan: 2 }, ''
        ]);
      }

      // this.getAlarmGenerates();
      this.getPerfil();

    }, error => {
      console.error(error);
      this.loadingform = false;
    });

  }

  getAlarmGenerates() {
    this.dataReport2.alarmsGenerated = [];
    this.farmsService.getAlarmsByHubAndSensor(this.id, '', '').subscribe(
      data => {
        const alarmsGenerates2 = data.data;
        alarmsGenerates2.forEach((element) => {
          const array = [
            {
              text: [
                {
                  text: '' + moment(element.alarm_date).format('DD/MM/YYYY')  + '\n'
                }, {
                  text: '' + element.alarm_hour + '\n'
                },
                {
                  text: '' + element.alarm_channel
                },
              ],
              border: [false, false, true, false],
              borderColor: ['#FFFFFF', '#FFFFFF', '#60813e', '#FFFFFF']
            },
            {
              text: [
                { text: '' + element.device_name + ' - ' + element.hub_name + '\n', style: ['strong'] },
                { text: '' + element.alarm_comment ? element.alarm_comment : '', fontSize: 8, style: ['justify'] }
              ],
              alignment: 'left',
              border: [true, false, false, false],
              borderColor: ['#60813e', '#FFFFFF', '#FFFFFF', '#FFFFFF']
            }
          ];
          this.dataReport2.alarmsGenerated.push(array);
          this.dataReport2.alarmsGenerated.push([
            { text: '', border: [false, false, true, false], borderColor: ['#FFFFFF', '#FFFFFF', '#60813e', '#FFFFFF'] },
            { text: '', border: [true, false, false, false], borderColor: ['#60813e', '#FFFFFF', '#FFFFFF', '#FFFFFF'] }
          ]);
        });
        this.getPerfil();
      },
      error => {
        console.error(error);
        this.loadingform = false;
      }
    );
  }

  getPerfil() {
    this.loadingform = true;
    const payload = this.perfilService.payload();
    this.perfilService.getPefil(payload.id).subscribe(
      data => {
        if (this.type === 'alarms') {
          this.dataReport2.generadoPor = '' + this.toTypeOration(data.name) + ' el ' + moment().format('DD/MM/YYYY') + ' a las ' + moment().format('h:mm a') + '.';
          this.getDuration();
          this.getSummaryFarm();
        } else if (this.type === 'farms') {
          this.dataReport1.generadoPor = '' + this.toTypeOration(data.name) + ' el ' + moment().format('DD/MM/YYYY') + ' a las ' + moment().format('h:mm a') + '.';
          this.getDuration();
          this.getSummaryFarm();
        }
      },
      error => {
        console.error(error);
        this.loadingform = false;
      }
    );
  }

  getSummaryFarm() {
    this.farmsService.getSummaryFarm(this.id).subscribe(
      (data) => {
        if (this.type === 'alarms') {
          this.dataReport2.farmName = data.name;
          if (this.format === 'xlsx') {
            this.generateAlarmsXLSX();
          }
          if (this.format === 'pdf') {
            this.generateAlarmsPDF();
          }
        }
        if (this.type === 'farms') {
          this.dataReport1.farmName = data.name;
          this.getHubsAndSensores();
        }
      },
      error => {
        console.error(error);
        this.loadingform = false;
      }
    );
  }

  getHubsAndSensores() {
    this.farmsService.report(this.type, '' + this.date, this.id, this.measurementType).subscribe(
      (data: any) => {
        this.dataReport1.hubs = data.hubs;
        this.dataReport1.graphValues = data.graphValues;
        if (this.format === 'xlsx') {
          this.generateFarmsXLSX();
        }
        if (this.format === 'pdf') {
          this.generateFarmsPDF();
        }
      },
      error => {
        console.error(error);
      });
  }

  getDuration() {
    let desdeHasta = '';
    switch (parseInt('' + this.date, 10)) {
      case 1:
        desdeHasta = moment().format('DD/MM/YYYY');
        break;
      case 2:
        desdeHasta = moment().subtract(1, 'day').format('DD/MM/YYYY');
        break;
      case 3:
        // const semanafin = moment().subtract(moment().weekday(), 'day');
        // const semanainicio = moment(semanafin.format('MM-DD-YYYY')).subtract(6, 'day');
        const semanainicio = moment().subtract(7, 'day').startOf('day');
        const semanafin = moment();
        desdeHasta = 'desde ' + semanainicio.format('DD/MM/YYYY') + ' hasta ' + semanafin.format('DD/MM/YYYY');
        break;
      case 4:
        // const mesfin = moment().subtract(moment().date(), 'day');
        // const mesinicio = moment(mesfin.format('MM-DD-YYYY')).subtract(mesfin.date() - 1, 'day');
        const mesinicio = moment().subtract(1, 'month').startOf('month');
        const mesfin = moment();
        desdeHasta = 'desde ' + mesinicio.format('DD/MM/YYYY') + ' hasta ' + mesfin.format('DD/MM/YYYY');
        break;
      case 5:
        // const fin = moment().subtract(moment().date(), 'day');
        // const inicio = moment(fin.format('MM-DD-YYYY')).subtract(fin.date() - 1, 'day');
        // inicio.subtract(6, 'months');
        const inicio = moment().subtract(6, 'month').startOf('month');
        const fin = moment();
        desdeHasta = 'desde ' + inicio.format('DD/MM/YYYY') + ' hasta ' + fin.format('DD/MM/YYYY');
        break;
    }
    this.dataReport1.duration = desdeHasta;
    this.dataReport2.duration = desdeHasta;
    this.getMeasurementType();
  }

  getMeasurementType() {
    const measurement = parseInt('' + this.measurementType, 10);
    const variable = this.measurementsTypes.find((element) => element.id === measurement);
    if (variable) {
      this.dataReport1.variable = this.toFirstUppercase(variable.name);
      this.dataReport2.variable = this.toFirstUppercase(variable.name);
    }
  }

  toTypeOration(text = '') {
    if (typeof text === 'string') {
      const array = text.split(' ');
      let newText = '';
      array.forEach((element, index) => {
        const space = index === (array.length - 1) ? '' : ' ';
        newText += element.charAt(0).toUpperCase() + element.slice(1, element.length).toLowerCase() + space;
      });
      return newText;
    }
    return text;
  }

  toFirstUppercase(text = '') {
    if (typeof text === 'string') {
      return text.charAt(0).toUpperCase() + text.slice(1, text.length).toLowerCase();
    }
    return text;
  }

  toGrades(num: number) {
    num = Math.abs(num);
    const grades = parseInt('' + num, 10);
    const minutesTemp = (num - grades) * 60;
    const minutes = parseInt('' + minutesTemp, 10);
    const seconds = parseInt('' + (minutesTemp - minutes) * 60, 10);
    return grades + '°' + minutes + '\'' + seconds + '\'\'';
  }

  async generateAlarmsPDF() {

    const pdf = {
      pageSize: 'A4',
      pageMargins: [40, 60, 40, 60 ],
      fontello: {
        font: 'fontello',
      },
      defaultStyle: {
        font: 'Montserrat'
      },
      background: [
        {
          image: await this.getBase64ImageFromURL('./assets/img/footer-pdf.png'),
          width: 595.28,
          height: 45,
          style: 'center',
          absolutePosition: { x: 0, y: 796.89 }
        }
      ],
      footer: (currentPage, pageCount) => {
        return [
          {
            text: [
              { text: 'Página ' + currentPage + '/' + pageCount + '   '},
              { text: 'Mail: ', style: 'strong'},
              { text: 'info@semva.co ' },
              { text: 'Tel: ', style: 'strong'},
              { text: '+57 (1) 311 9109' },
            ],
            style: ['center'],
            color: '#FFFFFF',
            margin: [0, 30, 0, 0],
          },
        ];
      },
      content: [],
      styles: {
        center: {
          alignment: 'center'
        },
        justify: {
          alignment: 'justify'
        },
        left: {
          alignment: 'left'
        },
        strong: {
          bold: true
        }
      }
    }

    pdf.content.push({
      image: await this.getBase64ImageFromURL('./assets/img/Grupo 406@2x.png'),
      width: 100,
      height: 120,
      style: 'center'
    });

    pdf.content.push({
      text: ' '
    });

    pdf.content.push({
      text: [
        {
          text: 'REPORTE: '
        },
        {
          text: 'ALERTAS CREADAS',
          style: ['strong'],
          fontSize: 16,
        }
      ],
      style: 'center'
    });

    pdf.content.push({
      columns: [
        {
          width: '*',
          alignment: 'center',
          stack: [
            {
              text: [
                {
                  text: 'Finca: ',
                  style: 'strong'
                },
                {
                  text: this.dataReport2.farmName
                }
              ]
            }
          ]
        }
      ]
    });

    pdf.content.push({
      text: ' '
    });

    pdf.content.push({
      columns: [
        {
          width: '*',
          alignment: 'center',
          stack: [
            {
              text: [
                {
                  text: 'Reporte generado por: ',
                  style: 'strong'
                },
                {
                  text: this.dataReport2.generadoPor
                }
              ]
            }
          ]
        }
      ]
    });

    pdf.content.push({
      columns: [
        {
          width: '*',
          alignment: 'center',
          stack: [
            {
              text: [
                {
                  text: 'Periodo de tiempo reportado: ',
                  style: 'strong'
                },
                {
                  text: this.dataReport2.duration
                }
              ]
            }
          ]
        }
      ]
    });

    pdf.content.push({
      columns: [
        {
          width: '*',
          alignment: 'center',
          stack: [
            {
              text: [
                {
                  text: 'Variable reportada: ',
                  style: 'strong'
                },
                {
                  text: this.dataReport2.variable
                }
              ]
            }
          ]
        }
      ]
    });

    pdf.content.push({
      text: ' '
    });

    pdf.content.push({
      text: ' '
    });

    pdf.content.push({
      text: this.dataReport2.textAlarm
    });

    pdf.content.push({
      text: ' '
    });

    pdf.content.push({ // layout: 'lightHorizontalLines', // optional
      table: {
        headerRows: 1,
        widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*'],
        body: this.dataReport2.alarms,
        fillColor: '#BDBCA6'
      },
      pageBreak: 'after'
    });

    pdf.content.push({
      image: await this.getBase64ImageFromURL('./assets/img/Grupo 406@2x.png'),
      width: 100,
      height: 120,
      style: 'center'
    });

    pdf.content.push({
      text: ' '
    });

    pdf.content.push({
      text: [
        {
          text: 'REPORTE: '
        },
        {
          text: 'ALERTAS GENERADAS',
          style: ['strong'],
          fontSize: 16,
        }
      ],
      style: 'center'
    });

    pdf.content.push({
      columns: [
        {
          width: '*',
          alignment: 'center',
          stack: [
            {
              text: [
                {
                  text: 'Finca: ',
                  style: 'strong'
                },
                {
                  text: this.dataReport2.farmName
                }
              ]
            }
          ]
        }
      ]
    });

    pdf.content.push({
      text: ' '
    });

    pdf.content.push({
      columns: [
        {
          width: '*',
          alignment: 'center',
          stack: [
            {
              text: [
                {
                  text: 'Reporte generado por: ',
                  style: 'strong'
                },
                {
                  text: this.dataReport2.generadoPor
                }
              ]
            }
          ]
        }
      ]
    });

    pdf.content.push({
      columns: [
        {
          width: '*',
          alignment: 'center',
          stack: [
            {
              text: [
                {
                  text: 'Periodo de tiempo reportado: ',
                  style: 'strong'
                },
                {
                  text: this.dataReport2.duration
                }
              ]
            }
          ]
        }
      ]
    });

    pdf.content.push({
      columns: [
        {
          width: '*',
          alignment: 'center',
          stack: [
            {
              text: [
                {
                  text: 'Variable reportada: ',
                  style: 'strong'
                },
                {
                  text: this.dataReport2.variable
                }
              ]
            }
          ]
        }
      ]
    });

    pdf.content.push({
      text: ' '
    });

    pdf.content.push({
      text: '&#xe800; 0xe800 ', font: 'fontello'
    });

    pdf.content.push({
      layout: {
        vLineWidth: (i, node) => {
          return 2.5;
        }
      },
      table: {
        headerRows: 0,
        widths: ['auto', '*'],
        body: this.dataReport2.alarmsGenerated,
      },
    });

    // pdfMake.createPdf(pdf).open();
    // pdfMake.createPdf(pdf).print();
    // pdfMake.createPdf(pdf).download();
    
    const pdfDocGenerator = pdfMake.createPdf(pdf);
    await pdfDocGenerator.getBlob((blob) => {
      fs.saveAs(blob, 'Reporte de alertas.pdf');
    });
    
    this.loadingform = false;

  }

  async generateFarmsPDF() {

    this.loadingform = true;
    const orientation = true; // [ true => 'landscape', false => 'portrait'];

    const backgroundFunction = (currentPage, pageSize) => {
      return `page ${currentPage} with size ${pageSize.width} x ${pageSize.height}`
    };

    const backgroundImage = [
      {
        image: orientation ? await this.getBase64ImageFromURL('./assets/img/footer-pdf-landscape.png') : await this.getBase64ImageFromURL('./assets/img/footer-pdf.png'),
        width: orientation ? 841.89 : 595.28,
        height: 45,
        style: 'center',
        absolutePosition: { x: 0, y: orientation ? 550.28 : 796.89 }
      }
    ];

    const pdf = {
      pageSize: 'A4',
      pageMargins: [40, 60, 40, 60],
      pageOrientation: orientation ? 'landscape' : 'portrait',
      fontello: {
        font: 'fontello',
      },
      defaultStyle: {
        font: 'Montserrat'
      },
      background: backgroundImage,
      footer: (currentPage, pageCount) => {
        return [
          {
            text: [
              { text: 'Página ' + currentPage + '/' + pageCount + '   ' },
              { text: 'Mail: ', style: 'strong' },
              { text: 'info@semva.co ' },
              { text: 'Tel: ', style: 'strong' },
              { text: '+57 (1) 311 9109' },
            ],
            style: ['center'],
            color: '#FFFFFF',
            margin: [0, 30, 0, 0],
          },
        ];
      },
      content: [],
      styles: {
        center: {
          alignment: 'center'
        },
        left: {
          alignment: 'left'
        },
        strong: {
          bold: true
        }
      }
    };

    pdf.content.push({
      image: await this.getBase64ImageFromURL('./assets/img/Grupo 406@2x.png'),
      width: 100,
      height: 120,
      style: 'center'
    });

    pdf.content.push({
      text: ' '
    });

    pdf.content.push({
      text: [
        {
          text: 'REPORTE: '
        },
        {
          text: this.dataReport1.farmName,
          style: ['strong'],
          fontSize: 16,
        }
      ],
      style: 'center'
    });

    pdf.content.push({
      text: ' '
    });

    pdf.content.push({
      columns: [
        {
          width: '*',
          alignment: 'center',
          stack: [
            {
              text: [
                {
                  text: 'Reporte generado por: ',
                  style: 'strong'
                },
                {
                  text: this.dataReport1.generadoPor
                }
              ]
            }
          ]
        }
      ]
    });

    pdf.content.push({
      columns: [
        {
          width: '*',
          alignment: 'center',
          stack: [
            {
              text: [
                {
                  text: 'Periodo de tiempo reportado: ',
                  style: 'strong'
                },
                {
                  text: this.dataReport1.duration
                }
              ]
            }
          ]
        }
      ]
    });

    pdf.content.push({
      columns: [
        {
          width: '*',
          alignment: 'center',
          stack: [
            {
              text: [
                {
                  text: 'Variable reportada: ',
                  style: 'strong'
                },
                {
                  text: this.dataReport1.variable
                }
              ]
            }
          ]
        }
      ]
    });

    pdf.content.push({
      text: ' '
    });

    pdf.content.push({
      text: 'HUB’s y sensores medidos:', style: 'strong', fontSize: 16
    });

    this.dataReport1.hubs.forEach((element) => {
      pdf.content.push({
        text: ' '
      });
      pdf.content.push({
        text: [
          { text: element.name, style: 'strong', fontSize: 14 },
          { text: ` (${this.toGrades(element.latitude)} ${element.latitude >= 0 ? 'N' : 'S'}, ${this.toGrades(element.longitude)} ${element.longitude >= 0 ? 'E' : 'O'})`, fontSize: 14 }
        ]
      });
      const sensors = {
        type: 'none',
        margin: [0, 4, 0, 0],
        ul: []
      };
      const dataSensors = { ul: [] };
      element.sensors.forEach((sensor) => {
        dataSensors.ul.push(sensor.name);
      });
      sensors.ul.push(dataSensors);
      pdf.content.push(sensors);
    });

    if (this.dataReport1.hubs.length === 0) {
      pdf.content.push({
        text: ' '
      });
      pdf.content.push({
        text: 'No hay datos disponibles para las opciones seleccionadas.'
      });
    }

    // this.dataReport1.graphValues.forEach((element) => {

    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < this.dataReport1.graphValues.length; index++) {

      const element = this.dataReport1.graphValues[index];

      const graph: any = {
        layout: 'noBorders',
        table: {
          headerRows: 2,
          widths: ['*'],
          body: []
        }
      };

      const text = [{
        text: [
          { text: `${this.toTypeOration(element.variable.name)} `, style: ['strong'], fontSize: 16 },
          { text: `${element.hub_name}`, fontSize: 10 },
        ]
      }];

      graph.table.body.push(text);

      if (element.data.length > 0) {

        const labels = [];
        const showdata = [];

        const data = element.data;
        // const data = this.extract(element.data);

        let defaultAxesx: TimeUnit = 'day';
        let defaultWithBorder = 2.4;
        const borderRadius = element.data.length == 1 ? 3 : 0;

        if (this.date === 1 || this.date === 2) {
          defaultAxesx = 'hour';
          defaultWithBorder = 3;
        }

        data.map(ondata => {
          labels.push(ondata.value);
          showdata.push(ondata.date);
        });

        const myChart: any = document.createElement('canvas');
        myChart.width = orientation ? 630 : 620; // 700-630 // 500-600
        myChart.height = orientation ? 327.50 : 320; // 437.50-327.50 // 300-400

        const ctx = (myChart as any).getContext('2d');

        const chart = new Chart(ctx, {
          type: 'line',
          data: {
            labels: showdata,
            datasets: [
              {
                label: 'Humedad',
                backgroundColor: 'rgba(230, 244, 253, 0.55)', // #e6f4fd
                borderColor: 'rgba(145, 220, 100, 0.65)', // #91dc64
                data: labels,
                borderWidth: defaultWithBorder
              }
            ]
          },
          options: {
            responsive: false,
            scales: {
              xAxes: [
                {
                  type: 'time',
                  time: {
                    unit: defaultAxesx,
                    displayFormats: {
                      day: 'DD MMM' // DD/MM/YYYY
                    },
                    tooltipFormat: 'DD/MM/YYYY h:mm:ss a'
                  },
                  ticks: {
                    // Include a dollar sign in the ticks
                    callback: (value, index, values) => {
                      return this.translateLabel(value);
                    }
                  },
                  scaleLabel: { display: true, labelString: 'Tiempo' },
                }
              ],
              yAxes: [
                {
                  scaleLabel: { display: true, labelString: element.variable.scale_string }, // 'Valores'
                }
              ]
            },
            elements: {
              point: {
                radius: borderRadius,
                pointStyle: 'circle'
              }
            },
            animation: {
              duration: 0
            },
            legend: {
              display: false
            },
          }
        });

        const width = orientation ? 630 : 620; 
        const height = orientation ? 327.50 : 320; 
        const html = `<div style="background-color: #fff;border-radius: 16px;padding: 12px; width: ${width}px; margin: 10px; border: solid 1px black;">
                      <style>
                      .font-montserrat-bold {
                          font-family: "Montserrat Bold";
                      }
                      .font-montserrat-italic {
                        font-family: "Montserrat Italic";
                      }
                      .font-montserrat-regular {
                          font-family: "Montserrat Regular";
                      }
                      @font-face {
                          font-family: "Montserrat Bold";
                          src: url("./assets/font/Montserrat-Bold.ttf");
                        }
                      @font-face {
                        font-family: "Montserrat Italic";
                        src: url("./assets/font/Montserrat-Italic.ttf");
                      }
                      @font-face {
                        font-family: "Montserrat Regular";
                        src: url("./assets/font/Montserrat-Regular.ttf");
                      }
                      </style>
                      <div style="padding-bottom: 6px;">
                      <span style="color: #60813e;" class="font-montserrat-regular">Último dato: ${moment(element.last_date_value).format('DD/MM/YYYY')} ${element.last_hour_value}</span>
                      <span style="float: right; color: #60813e;" class="font-montserrat-regular">Datos ${this.dataReport1.duration}</span>
                      </div>
                      <div style="width: ${width}px; height: ${height}px;">
                      <img style="width: ${width}px; height: ${height}px;" src="${myChart.toDataURL('image/png', 1)}"/>
                      </div>
                      <div style="color: #60813e; padding-top: 6px;" class="font-montserrat-regular">
                      <span style="font-weight: bold;">Último valor:</span> ${element.last_value}
                      <span style="margin-left: 10px;">Max: ${element.max_value}</span>
                      <span style="margin-left: 10px;">Min: ${element.min_value}</span>
                      <span style="margin-left: 10px;">Promedio: ${element.avg_value}</span>
                      </div>
                      </div>`;

        const canvas: any = document.getElementById('myChart2');
        const context = canvas.getContext('2d');
        let grafica = '';

        await rasterizeHTML.drawHTML(html, canvas).then((renderResult) => {
          context.drawImage(renderResult.image, 0, 0);
          grafica = canvas.toDataURL();
        });

        const graphStatics = [
          {
            image: canvas.toDataURL('image/png', 1),
            width: orientation ? 700 : 400,
            height: orientation ? 437.50 : 250,
            style: 'center'
          }
        ];

        if (orientation) {
          graph.pageBreak = 'before';
        } else {
          pdf.content.push({ text: ' ' });
        }

        graph.table.body.push(graphStatics);
        pdf.content.push(graph);

      } else {

        const width = orientation ? 630 : 620; 
        const height = orientation ? 327.50 : 320; 
        const html = `<div style="background-color: #fff;border-radius: 16px;padding: 12px; width: ${width}px; margin: 10px; border: solid 1px black;">
                      <style>
                      .font-montserrat-bold {
                          font-family: "Montserrat Bold";
                      }
                      .font-montserrat-italic {
                        font-family: "Montserrat Italic";
                      }
                      .font-montserrat-regular {
                          font-family: "Montserrat Regular";
                      }
                      @font-face {
                          font-family: "Montserrat Bold";
                          src: url("./assets/font/Montserrat-Bold.ttf");
                        }
                      @font-face {
                        font-family: "Montserrat Italic";
                        src: url("./assets/font/Montserrat-Italic.ttf");
                      }
                      @font-face {
                        font-family: "Montserrat Regular";
                        src: url("./assets/font/Montserrat-Regular.ttf");
                      }
                      </style>
                      <div style="padding-bottom: 6px;  visibility: hidden;">
                        <span style="color: #60813e;" class="font-montserrat-regular">Último dato: </span>
                        <span style="float: right; color: #60813e;" class="font-montserrat-regular">Datos </span>
                      </div>
                      <div style="width: ${width}px; height: ${height}px; display: grid;align-items: center;">
                        <div style="text-align: center;color: #777272;">
                          <div>
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
                            AAABuwAAAbsBOuzj4gAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAggSURBVHic7VthaFvXFf7OfbIkS5Zq69lNq2xJ
                            SZsfS0tZiLfQEZrZslkzYqdsoCZ/FtqmhXX70S2EUFoWOlZG6brux9bB5iTLYKQ1dCxOaFJi2XiYttkc0h+LGWQxbSDKHFuqJ1uOLEvv7Iee
                            Zb93r2xZshpp8wf68865V+9899x7zz33PGJmVBJtbW0NRNRORFuIKGgYRpCIggAWfgAQBRBl5qgQIsrMUWYeY+aBwcHBmUq+H1WCgFAotBFA
                            F4BuAO0AXCV2NQdgAEAfgLORSOTm2rzhItaMgO7ubk8ymXyBiJ5i5h0AaE06XgQT0WVmftfr9b7d19c3uxadlk1AOBzWYrHY0wBexaJLVxpR
                            AMd0XT/Z29ubLaejsgjo6OjYy8yvA9hWzkuUgVEiOtrf33+u1A5KIiAUCm0GcArA7mL0m/0ZdO2cQVDPoNGbRYM7C7eT4dAAECGR1DD+Hydi
                            CYFYQsOtuIaRay4kU6LYVxoCcDASiXy2WltWTUBnZ+cuwzD+DKBlOb0HNszjyccSeGRzCu66nJcSAfUuAY+bUOdYfonIZAlXrjsxfNWND0fr
                            EZ9ekYwJIcR3Ll68OLwKc1ZHQHt7+zNE9FsAzkI6nduTOLB7Ck7H4tR01hHqXYR6lwCVsDQyA5+MuXDiAx9GbxT8awBIM/P3BwYGThTbd1EE
                            mAvdGwB+VEhnx0MpPL8nDq8rk3+maYR7vAIu59ptCMNX3Th+wY8bE47l1N7Sdf1IMQvkigSEw2EtHo+/x8z7VPINjRm8FJ5Esz9tee6tF/B5
                            ShvxlWAYwPsjHvSc92P6jnpqENGZQCDw3ZVIWHFixWKxNwoZ37o1hTcP3bIY79AIzY0a/N7KGA8AQgB7vz6L3/xwEpvuzSh1mHmf6bXLYlkP
                            MOf8cZVs/+MJdO2cwtLmTgeh6R4NokKGqzA7R3jtdBM+/qdbKWfmZ5dbEwoSYK72ESgWvJf3T+IrX7IGYi4nocmnVWzUlwMz0HPBj3eGGlTi
                            tBAiVGh3UBJg7vN/h2KrUxlf7yI0+rSiXjaTJUwm1DOv2W/AoZUemP3+fEESJgB8TRUnFFpKT0Fh/P7HE5LxzrrijQeAaFzD02/eq5SdPHwb
                            m1rUc7oYHHoigU/HHarp0IKcTd+0C6Sh6Ojo2AtFhNe6NYWunVPWxgKrMr7SIAJePvB5oYVxt2mbBRYCwuGwZsb2FmxozODFfROwz5ZGnwat
                            6Gj1i4HHxfjZwTh89YYkY+bXw+GwZcQsr2+e6qSDzUvhSQBW6xs8Aq66u7DiFYGNegaH9iRUom2mjXnkCeju7vYgd6S1YMdDKSnIEQJoqK+y
                            obfh262zhdaTV01bASwhIJlMvgDFef75PXGph4b60oMcp8b4cktG+XOWsQPYIQTw7BNKLwiatgJYsguYmRyLZuf2pCW2X+jY4y599O8LZPGH
                            w7dLbr8a7Ho4hW2b0tIBioieAvALwPSAUCi00UxjWXBg95T9UVmjfzfwzLempWfMvMPMW+anQBdsObwHNsxbjrRAbpspZ/TvBr66ZQ4Bn7Qj
                            EHI25wnotms8+Zg8f5x1VFOjD+QG7Rvb7qhE3QDgaGtraxBCtNulj2xOSS3ca3Cu//fnGn7yx4BS9tPvxXFfU1k5TiV2PZzCuUte++P2tra2
                            BgcRSXn7Zn8mn8ZaCpezfPdPZwjXb9UVlFUC2x9Mw+s27DlGFxG1CyLaYm/QtVO+jHFoVHVRX7FwaIzWrXPScyLaIsxrKguCuhxAOJbNQFU/
                            7g/IHk1EQWEYhkRAo1dW1r7ILEcFoPtlmwzDCCo9oMEtK9e4/aqtMOcBUIS/bqesLGp0/i9A9yl3FzUBdYqYvNanQMAvDypMAv6vIZC7abVg
                            PiuPdtaobCFFpRFX5yGjSgJSaVnZUHpQ7SA2rUzdRQUzSwTMpGTlGncA5eWqWZIjJAKmkjIBtT4FYgnZJiGE2gOiMTnsy5Sera4K3IrLBDBz
                            VDDzmF1w9pJ8uZDJMrI1ug5ksoSRa3KdFjOPCWYeQK4aK4/JhAOpeZmxuXRtMnDlulNVbTLHzAPCrMMbsEv/8Zl82ZhK1+Y6MHxVeXE6MDg4
                            OLNAS59d+peP/FKL9DxLlyPVDmbgw9F6lagPWEyJnYXt5uPT8TqkM9ZpwAzMpmprGnwy5lJtgYyczTkCIpHITSK6bNc6PdQodThzx6gpLzjx
                            gU96RkSXF6pO89Qw87t2xYtXvEjOWbdEw6gdLxi+6lYWVS21NU+A1+t9G4qw+Hfn5QRmLXiBYQDHL8jrGICoaSuAJQSYtbfH7NqX/+XGZMLK
                            omHkSKhmvD/iKVRJdmxpnbFlddB1/SSAUXuLn/c2w177PDNrYG6+Ot3gZsyBnvPK0R81bczDQkBvb2+WiI7aW41POfCrMy3SpcjUdLbqosPZ
                            OcIrpwLK8jkiOmovm5O0zMLjIfvzkWtunL1k3RUMI0dCtYAZeO10E27cVrr+kKqoulCy+yAURVLv/NWPB4NpS51Qep4xNZ0tulQmGMjiT0fH
                            lbJmddqqaPRc8Bcql5tAziYJ62Vy64WSK2zooVDolyhQJN26NYUX901gaRTt0AiNPrFiOfxa4GbMgVdOBQrNeQB4KxKJ/Hi5PlbMCuu6foSI
                            zqhkI9fcONxzvyVOyGQZk1NZJJKVC5YMAzj3Nw9+8OvmgsYT0Rld14+s1Nd6ufz6BxPrn8ysfzS12jZ5/C98NlfW3WB/f/85XdcfBfAcFEfp
                            CiIK4Dld1x8tx3hg/dPZ9Y+nK0LAUlT75/P/BbCt4MIEaN9AAAAAAElFTkSuQmCC">
                          </div>
                          <div style="font-size: 20px; margin-top: 6px;">No hay datos disponibles para esta gráfica</div>
                        </div>
                      </div>
                      <div style="color: #60813e; padding-top: 6px; visibility: hidden;" class="font-montserrat-regular">
                        <span style="font-weight: bold;">Último valor:</span>
                        <span style="margin-left: 10px;">Max: </span>
                        <span style="margin-left: 10px;">Min: </span>
                        <span style="margin-left: 10px;">Promedio: </span>
                      </div>
                    </div>`;

        const canvas: any = document.getElementById('myChart2');
        const context = canvas.getContext('2d');
        let grafica = '';

        await rasterizeHTML.drawHTML(html, canvas).then((renderResult) => {
          context.drawImage(renderResult.image, 0, 0);
          grafica = canvas.toDataURL();
        });

        const graphStatics = [
          {
            image: grafica,
            width: orientation ? 700 : 400,
            height: orientation ? 437.50 : 250,
            style: 'center'
          }
        ];

        if (orientation) {
          graph.pageBreak = 'before';
        } else {
          pdf.content.push({ text: ' ' });
        }

        graph.table.body.push(graphStatics);
        pdf.content.push(graph);

      }

    }

    // });

    // pdfMake.createPdf(pdf).download();
    
    const pdfDocGenerator = pdfMake.createPdf(pdf);
    await pdfDocGenerator.getBlob((blob) => {
      fs.saveAs(blob, 'Reporte de hubs, sensores y mediciones.pdf');
    });

    this.loadingform = false;
    
  }

  async generateAlarmsXLSX() {

    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Tee S.A.S';

    const worksheet = workbook.addWorksheet('Alertas creadas');

    const myBase64Image = await this.getBase64ImageFromURL('./assets/img/Grupo 406@2x.png');
    const image = workbook.addImage({
      base64: myBase64Image,
      extension: 'png',
    });

    worksheet.addImage(image, 'M1:N7');

    const titleRow = worksheet.addRow(['Reporte de alertas creadas']);
    titleRow.font = { name: 'Arial', size: 16, bold: true };
    titleRow.alignment = { vertical: 'bottom', horizontal: 'center' };
    worksheet.mergeCells('A1:N2');
    const cellA1W1 = worksheet.getCell('A1');
    cellA1W1.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const tt = worksheet.addRow([]);
    worksheet.mergeCells('A3:N3');
    const cellA3W1 = worksheet.getCell('A3');
    cellA3W1.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const titleRow2 = worksheet.addRow(['Finca: ' + this.dataReport2.farmName]);
    titleRow2.font = { name: 'Arial', size: 12, bold: true };
    titleRow2.alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.mergeCells('A4:N4');
    const cellA4W1 = worksheet.getCell('A4');
    cellA4W1.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const titleRow3 = worksheet.addRow(['Reporte generado por: ' + this.dataReport2.generadoPor]);
    titleRow3.font = { name: 'Arial', size: 12, bold: true };
    titleRow3.alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.mergeCells('A5:N5');
    const cellA5W1 = worksheet.getCell('A5');
    cellA5W1.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const titleRow4 = worksheet.addRow(['Periodo de tiempo reportado: ' + this.dataReport2.duration]);
    titleRow4.font = { name: 'Arial', size: 12, bold: true };
    titleRow4.alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.mergeCells('A6:N6');
    const cellA6W1 = worksheet.getCell('A6');
    cellA6W1.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const titleRow5 = worksheet.addRow(['Variable reportada: ' + this.dataReport2.variable]);
    titleRow5.font = { name: 'Arial', size: 12, bold: true };
    titleRow5.alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.mergeCells('A7:N7');
    const cellA7W1 = worksheet.getCell('A7');
    cellA7W1.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    worksheet.addRow([]);
    worksheet.mergeCells('A8:N8');
    const cellA8W1 = worksheet.getCell('A8');
    cellA8W1.border = { bottom: { style: 'thin', color: { argb: '000000' } } };
    cellA8W1.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const headerTable = worksheet.addRow(['Estado', 'Variable', 'Dispositivo', 'Valor limite', 'Regla', 'Canal', 'Receptor']);
    headerTable.font = { name: 'Calibri', size: 11, bold: true };

    const spaceSheet1 = [10, 10, 10, 10, 10, 10, 10];

    this.dataReport2.xlsx.alarms.forEach((element) => {
      const state = parseInt(element.status, 10) === 1 ? 'activo' : 'inactivo';
      worksheet.addRow([
        state,
        element.variable.name,
        element.device.name,
        element.limit_value,
        element.alarm_rule,
        element.alarm_channel,
        element.receptor.name
      ]);
      spaceSheet1[0] = ('' + state).length > spaceSheet1[0] ? ('' + state).length : spaceSheet1[0];
      spaceSheet1[1] = ('' + element.variable.name).length > spaceSheet1[1] ? ('' + element.variable.name).length : spaceSheet1[1];
      spaceSheet1[2] = ('' + element.device.name).length > spaceSheet1[2] ? ('' + element.device.name).length : spaceSheet1[2];
      spaceSheet1[3] = ('' + element.limit_value).length > spaceSheet1[3] ? ('' + element.limit_value).length : spaceSheet1[3];
      spaceSheet1[4] = ('' + element.alarm_rule).length > spaceSheet1[4] ? ('' + element.alarm_rule).length : spaceSheet1[4];
      spaceSheet1[5] = ('' + element.alarm_channel).length > spaceSheet1[5] ? ('' + element.alarm_channel).length : spaceSheet1[5];
      spaceSheet1[6] = ('' + element.receptor.name).length > spaceSheet1[6] ? ('' + element.receptor.name).length : spaceSheet1[6];
    });

    for (let index = 0; index < spaceSheet1.length; index++) {
      const width = spaceSheet1[index];
      const column = worksheet.columns[index];
      column.width = width + 2;
    }

    // tslint:disable-next-line:prefer-for-of
    // for (let i = 0; i < worksheet.columns.length; i += 1) {
    //   let dataMax = 0;
    //   const column = worksheet.columns[i];
    //   for (let j = 9; j < column.values.length; j += 1) {
    //     if (column.values[j]) {
    //       const columnLength = column.values[j].length;
    //       if (columnLength > dataMax) {
    //         dataMax = columnLength;
    //       }
    //     }
    //   }
    //   column.width = dataMax < 10 ? 10 : dataMax + 2;
    // }

    const worksheet2 = workbook.addWorksheet('Alertas generadas');

    worksheet2.addImage(image, 'M1:N7');

    const titleRowW2 = worksheet2.addRow(['Reporte de alertas generadas']);
    titleRowW2.font = { name: 'Arial', size: 16, bold: true };
    titleRowW2.alignment = { vertical: 'bottom', horizontal: 'center' };
    worksheet2.mergeCells('A1:N2');
    const cellA1W2 = worksheet2.getCell('A1');
    cellA1W2.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const ttW2 = worksheet2.addRow([]);
    worksheet2.mergeCells('A3:N3');
    const cellA3W2 = worksheet2.getCell('A3');
    cellA3W2.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const titleRow2W2 = worksheet2.addRow(['Finca: ' + this.dataReport2.farmName]);
    titleRow2W2.font = { name: 'Arial', size: 12, bold: true };
    titleRow2W2.alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet2.mergeCells('A4:N4');
    const cellA4W2 = worksheet2.getCell('A4');
    cellA4W2.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const titleRow3W2 = worksheet2.addRow(['Reporte generado por: ' + this.dataReport2.generadoPor]);
    titleRow3W2.font = { name: 'Arial', size: 12, bold: true };
    titleRow3W2.alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet2.mergeCells('A5:N5');
    const cellA5W2 = worksheet2.getCell('A5');
    cellA5W2.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const titleRow4W2 = worksheet2.addRow(['Periodo de tiempo reportado: ' + this.dataReport2.duration]);
    titleRow4W2.font = { name: 'Arial', size: 12, bold: true };
    titleRow4W2.alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet2.mergeCells('A6:N6');
    const cellA6W2 = worksheet2.getCell('A6');
    cellA6W2.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const titleRow5W2 = worksheet2.addRow(['Variable reportada: ' + this.dataReport2.variable]);
    titleRow5W2.font = { name: 'Arial', size: 12, bold: true };
    titleRow5W2.alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet2.mergeCells('A7:N7');
    const cellA7W2 = worksheet2.getCell('A7');
    cellA7W2.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    worksheet2.addRow([]);
    worksheet2.mergeCells('A8:N8');
    const cellA8W2 = worksheet2.getCell('A8');
    cellA8W2.border = { bottom: { style: 'thin', color: { argb: '000000' } } };
    cellA8W2.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const headerTable2 = worksheet2.addRow(['Fecha', 'Hora', 'Canal', 'Sensor', 'Dispositivo', 'Comentario']);
    headerTable2.font = { name: 'Calibri', size: 11, bold: true };

    const spaceSheet2 = [10, 10, 10, 10, 10, 10];

    this.dataReport2.xlsx.alarmsGenerated.forEach((element) => {
      const fecha = moment(element.alarm_date);
      worksheet2.addRow([
        new Date(parseInt(fecha.format('YYYY'), 10), parseInt(fecha.format('MM'), 10) - 1, parseInt(fecha.format('DD'), 10)),
        element.alarm_hour,
        element.alarm_channel,
        element.device_name,
        element.hub_name,
        element.alarm_comment,
      ]);
      spaceSheet2[0] = ('' + '          ').length > spaceSheet2[0] ? ('' + '          ').length : spaceSheet2[0];
      spaceSheet2[1] = ('' + element.alarm_hour).length > spaceSheet2[1] ? ('' + element.alarm_hour).length : spaceSheet2[1];
      spaceSheet2[2] = ('' + element.alarm_channel).length > spaceSheet2[2] ? ('' + element.alarm_channel).length : spaceSheet2[2];
      spaceSheet2[3] = ('' + element.device_name).length > spaceSheet2[3] ? ('' + element.device_name).length : spaceSheet2[3];
      spaceSheet2[4] = ('' + element.hub_name).length > spaceSheet2[4] ? ('' + element.hub_name).length : spaceSheet2[4];
      spaceSheet2[5] = ('' + element.alarm_comment).length > spaceSheet2[5] ? ('' + element.alarm_comment).length : spaceSheet2[5];
    });

    for (let index = 0; index < spaceSheet2.length; index++) {
      const width = spaceSheet2[index];
      const column = worksheet2.columns[index];
      if (index == 5) {
        for (let j = 9; j < column.values.length; j += 1) {
          const cell = worksheet2.getCell('F' + j);
          cell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
        }
        column.width = 60;
      } else {
        column.width = width + 2;
      }
    }

    // tslint:disable-next-line:prefer-for-of
    // for (let i = 0; i < worksheet2.columns.length; i += 1) {
    //   let dataMax = 0;
    //   const column = worksheet2.columns[i];
    //   for (let j = 9; j < column.values.length; j += 1) {
    //     if (i === 5) {
    //       const cell = worksheet2.getCell('F' + j);
    //       cell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
    //     }
    //     if (column.values[j]) {
    //       const columnLength = column.values[j].length;
    //       if (columnLength > dataMax) {
    //         dataMax = columnLength;
    //       }
    //     }
    //   }
    //   if (i === 5) {
    //     column.width = 60;
    //   } else {
    //     column.width = dataMax < 10 ? 10 : dataMax + 2;
    //   }
    // }

    await workbook.xlsx.writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'Reporte de alertas.xlsx');
      });

    this.loadingform = false;
  
  }

  async generateFarmsXLSX() {

    // const timeStart = moment();

    // const duration = () => {
    //   const diff = moment.duration(moment().diff(timeStart));
    //   return `${diff.hours()} horas ${diff.minutes()} minutos ${diff.seconds()} segundos`;
    // }

    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Tee S.A.S';

    const worksheet = workbook.addWorksheet('HUBs y sensores');

    const myBase64Image = await this.getBase64ImageFromURL('./assets/img/Grupo 406@2x.png');
    const image = workbook.addImage({
      base64: myBase64Image,
      extension: 'png',
    });

    worksheet.addImage(image, 'M1:N7');

    const titleRow = worksheet.addRow(['HUBs y sensores']);
    titleRow.font = { name: 'Arial', size: 16, bold: true };
    titleRow.alignment = { vertical: 'bottom', horizontal: 'center' };
    worksheet.mergeCells('A1:N2');
    const cellA1W1 = worksheet.getCell('A1');
    cellA1W1.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const tt = worksheet.addRow([]);
    worksheet.mergeCells('A3:N3');
    const cellA3W1 = worksheet.getCell('A3');
    cellA3W1.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const titleRow2 = worksheet.addRow(['Finca: ' + this.dataReport1.farmName]);
    titleRow2.font = { name: 'Arial', size: 12, bold: true };
    titleRow2.alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.mergeCells('A4:N4');
    const cellA4W1 = worksheet.getCell('A4');
    cellA4W1.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const titleRow3 = worksheet.addRow(['Reporte generado por: ' + this.dataReport1.generadoPor]);
    titleRow3.font = { name: 'Arial', size: 12, bold: true };
    titleRow3.alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.mergeCells('A5:N5');
    const cellA5W1 = worksheet.getCell('A5');
    cellA5W1.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const titleRow4 = worksheet.addRow(['Periodo de tiempo reportado: ' + this.dataReport1.duration]);
    titleRow4.font = { name: 'Arial', size: 12, bold: true };
    titleRow4.alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.mergeCells('A6:N6');
    const cellA6W1 = worksheet.getCell('A6');
    cellA6W1.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const titleRow5 = worksheet.addRow(['Variable reportada: ' + this.dataReport1.variable]);
    titleRow5.font = { name: 'Arial', size: 12, bold: true };
    titleRow5.alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.mergeCells('A7:N7');
    const cellA7W1 = worksheet.getCell('A7');
    cellA7W1.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    worksheet.addRow([]);
    worksheet.mergeCells('A8:N8');
    const cellA8W1 = worksheet.getCell('A8');
    cellA8W1.border = { bottom: { style: 'thin', color: { argb: '000000' } } };
    cellA8W1.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const headerTable = worksheet.addRow(['Dispositivo', 'Latitud', 'Longitud', 'Sensor']);
    headerTable.font = { name: 'Calibri', size: 11, bold: true };

    const spaceSheet1 = [10, 10, 10, 10];

    this.dataReport1.hubs.forEach((element) => {
      element.sensors.forEach((element2) => {
        const latitude = '' + this.toGrades(element.latitude) + ' ' + (element.latitude >= 0 ? 'N' : 'S');
        const longitude = '' + this.toGrades(element.longitude) + ' ' + (element.longitude >= 0 ? 'E' : 'O');
        worksheet.addRow([
          element.name,
          latitude,
          longitude,
          element2.name,
        ]);
        spaceSheet1[0] = ('' + element.name).length > spaceSheet1[0] ? ('' + element.name).length : spaceSheet1[0];
        spaceSheet1[1] = ('' + latitude).length > spaceSheet1[1] ? ('' + latitude).length : spaceSheet1[1];
        spaceSheet1[2] = ('' + longitude).length > spaceSheet1[2] ? ('' + longitude).length : spaceSheet1[2];
        spaceSheet1[3] = ('' + element2.name).length > spaceSheet1[3] ? ('' + element2.name).length : spaceSheet1[3];
      });
    });

    for (let index = 0; index < spaceSheet1.length; index++) {
      const width = spaceSheet1[index];
      const column = worksheet.columns[index];
      column.width = width + 2;
    }

    // tslint:disable-next-line:prefer-for-of
    // for (let i = 0; i < worksheet.columns.length; i += 1) {
    //   let dataMax = 0;
    //   const column = worksheet.columns[i];
    //   for (let j = 9; j < column.values.length; j += 1) {
    //     if (column.values[j]) {
    //       const columnLength = column.values[j].length;
    //       if (columnLength > dataMax) {
    //         dataMax = columnLength;
    //       }
    //     }
    //   }
    //   column.width = dataMax < 10 ? 10 : dataMax + 2;
    // }

    const worksheet2 = workbook.addWorksheet('Mediciones');

    worksheet2.addImage(image, 'M1:N7');

    const titleRowW2 = worksheet2.addRow(['Mediciones']);
    titleRowW2.font = { name: 'Arial', size: 16, bold: true };
    titleRowW2.alignment = { vertical: 'bottom', horizontal: 'center' };
    worksheet2.mergeCells('A1:N2');
    const cellA1W2 = worksheet2.getCell('A1');
    cellA1W2.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const ttW2 = worksheet2.addRow([]);
    worksheet2.mergeCells('A3:N3');
    const cellA3W2 = worksheet2.getCell('A3');
    cellA3W2.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const titleRow2W2 = worksheet2.addRow(['Finca: ' + this.dataReport1.farmName]);
    titleRow2W2.font = { name: 'Arial', size: 12, bold: true };
    titleRow2W2.alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet2.mergeCells('A4:N4');
    const cellA4W2 = worksheet2.getCell('A4');
    cellA4W2.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const titleRow3W2 = worksheet2.addRow(['Reporte generado por: ' + this.dataReport1.generadoPor]);
    titleRow3W2.font = { name: 'Arial', size: 12, bold: true };
    titleRow3W2.alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet2.mergeCells('A5:N5');
    const cellA5W2 = worksheet2.getCell('A5');
    cellA5W2.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const titleRow4W2 = worksheet2.addRow(['Periodo de tiempo reportado: ' + this.dataReport1.duration]);
    titleRow4W2.font = { name: 'Arial', size: 12, bold: true };
    titleRow4W2.alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet2.mergeCells('A6:N6');
    const cellA6W2 = worksheet2.getCell('A6');
    cellA6W2.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const titleRow5W2 = worksheet2.addRow(['Variable reportada: ' + this.dataReport1.variable]);
    titleRow5W2.font = { name: 'Arial', size: 12, bold: true };
    titleRow5W2.alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet2.mergeCells('A7:N7');
    const cellA7W2 = worksheet2.getCell('A7');
    cellA7W2.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    worksheet2.addRow([]);
    worksheet2.mergeCells('A8:N8');
    const cellA8W2 = worksheet2.getCell('A8');
    cellA8W2.border = { bottom: { style: 'thin', color: { argb: '000000' } } };
    cellA8W2.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

    const headerTable2 = worksheet2.addRow(['Dispositivo', 'Variable', 'Fecha', 'Hora', 'Valor', 'Unidad']);
    headerTable2.font = { name: 'Calibri', size: 11, bold: true };

    const spaceSheet2 = [10, 10, 10, 10, 10, 10];

    this.dataReport1.graphValues.forEach((element) => {
      element.data.forEach((element2) => {
        const fecha = moment(element2.date);
        worksheet2.addRow([
          element.hub_name,
          element.variable.name,
          new Date(parseInt(fecha.format('YYYY'), 10), parseInt(fecha.format('MM'), 10) - 1, parseInt(fecha.format('DD'), 10)),
          fecha.format('hh:mm A'),
          element2.value,
          element.variable.scale,
        ]);
        spaceSheet2[0] = ('' + element.hub_name).length > spaceSheet2[0] ? ('' + element.hub_name).length : spaceSheet2[0];
        spaceSheet2[1] = ('' + element.variable.name).length > spaceSheet2[1] ? ('' + element.variable.name).length : spaceSheet2[1];
        spaceSheet2[2] = ('' + '          ').length > spaceSheet2[2] ? ('' + '          ').length : spaceSheet2[2];
        spaceSheet2[3] = ('' + '        ').length > spaceSheet2[3] ? ('' + '        ').length : spaceSheet2[3];
        spaceSheet2[4] = ('' + element2.value).length > spaceSheet2[4] ? ('' + element2.value).length : spaceSheet2[4];
        spaceSheet2[5] = ('' + element.variable.scale).length > spaceSheet2[5] ? ('' + element.variable.scale).length : spaceSheet2[5];
      });
    });

    for (let index = 0; index < spaceSheet2.length; index++) {
      const width = spaceSheet2[index];
      const column = worksheet2.columns[index];
      column.width = width + 2;
    }

    // tslint:disable-next-line:prefer-for-of
    // for (let i = 0; i < worksheet2.columns.length; i += 1) {
    //   let dataMax = 0;
    //   const column = worksheet2.columns[i];
    //   for (let j = 9; j < column.values.length; j += 1) {
    //     if (column.values[j]) {
    //       const columnLength = column.values[j].length;
    //       if (columnLength > dataMax) {
    //         dataMax = columnLength;
    //       }
    //     }
    //   }
    //   column.width = dataMax < 10 ? 10 : dataMax + 2;
    // }

    await workbook.xlsx.writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'Reporte de hubs, sensores y mediciones.xlsx');
      });

    this.loadingform = false;

  }

  generateXLSX() {

    this.loadingform = true;

    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Tee S.A.S';

    const worksheet = workbook.addWorksheet('HUBs y sensores');

    const headerTable = worksheet.addRow(['Dispositivo', 'Latitud', 'Longitud', 'Sensor']);
    headerTable.font = { name: 'Calibri', size: 11, bold: true };

    const data = [
      {
        sensors: ''
      }
    ];

    this.dataReport1.hubs.forEach((element) => {
      element.sensors.forEach((element2) => {
        worksheet.addRow([
          element.name,
          '' + this.toGrades(element.latitude) + ' ' + (element.latitude >= 0 ? 'N' : 'S'),
          '' + this.toGrades(element.longitude) + ' ' + (element.longitude >= 0 ? 'E' : 'O'),
          element2.name,
        ]);
      });
    });

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < worksheet.columns.length; i += 1) {
      let dataMax = 0;
      const column = worksheet.columns[i];
      for (let j = 1; j < column.values.length; j += 1) {
        const columnLength = column.values[j].length;
        if (columnLength > dataMax) {
          dataMax = columnLength;
        }
      }
      column.width = dataMax < 10 ? 10 : dataMax + 2;
    }

    workbook.xlsx.writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'Reporte de hubs, sensores y mediciones.xlsx');
      });

    this.loadingform = false;
  }

  extract(temp1, time = []) {
    let newData = [];
    const start = moment(new Date(temp1[0].date));
    const end = moment(new Date(temp1[temp1.length - 1].date));
    const register = time.length > 0 ? time : [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];
    while(start.isSameOrBefore(end)){
      let days = temp1.filter(element => {
        return start.format('YYYY-MM-DD') == moment(element.date).format('YYYY-MM-DD');
      });
      for(let i = 0; i < register.length; i++){
        const hours = days.filter(element => {
          return moment(element.date).hour() == register[i];
        });
        if(hours.length > 0){
          // newData.push(hours[0]);
          let sum = 0;
          hours.forEach((element) => {
            sum += element.value;
          });
          let ave = sum / hours.length;
          ave = parseFloat(ave.toFixed(1));
          newData.push({ value: ave, date: moment(new Date(hours[0].date)).format('YYYY-MM-DD HH:00:00') });
        }
        days = days.slice(hours.length);
      }
      start.add(1, 'days');
      temp1 = temp1.slice(days.length);
    }
    return newData;
  }

  translateMonth( month ) {

    var result = month;

    switch(month) {

        case 'Jan':
            result = 'Ene' ;
            break;
        case 'Feb':
            result = 'Feb' ;
            break;
        case 'Mar':
            result = 'Mar' ;
            break;
        case 'Apr':
            result = 'Abr' ;
            break;
        case 'May':
            result = 'May' ;
            break;
        case 'Jun':
            result = 'Jun' ;
            break;
        case 'Jul':
            result = 'Jul' ;
            break;
        case 'Aug':
            result = 'Ago' ;
            break;
        case 'Sep':
            result = 'Sep' ;
            break;
        case 'Oct':
            result = 'Oct' ;
            break;
        case 'Nov':
            result = 'Nov' ;
            break;
        case 'Dec':
            result = 'Dic' ;
            break;

    }

    return result;
  }

  translateLabel( label ) {

      var month = label.match(/Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Nov|Dec/g);

      if (!month) {
        return label;
      }

      const translation = this.translateMonth(month[0]);
      return label.replace( month, translation, 'g' );
  }

}
