import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-menu-navigation',
  templateUrl: './menu-navigation.component.html',
  styleUrls: ['./menu-navigation.component.css']
})
export class MenuNavigationComponent implements OnInit {

  farmId;
  generalActive;
  viewAlarms;
  viewUsers;
  viewReport;
  viewHubs;
  viewSensors;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private usersService: UsersService) {
    this.activatedRoute.params.subscribe(
      params => {
        this.farmId = params.id;
        this.generalActive = location.pathname.replace(this.farmId, '') === '/farms//details/general';
        this.viewAlarms = location.pathname.replace(this.farmId, '') === '/farms//alarms' || location.pathname.replace(this.farmId, '') === '/farms//alarms/config';
        this.viewReport = location.pathname.replace(this.farmId, '') === '/farms//report-generator';
        this.viewHubs = location.pathname.replace(this.farmId, '') === '/farms//hubs' || location.pathname.replace(this.farmId, '') === '/farms//hubs/create';
        this.viewSensors = location.pathname.replace(this.farmId, '') === '/farms//sensors' || location.pathname.replace(this.farmId, '') === '/farms//sensors/create';
        this.viewUsers = location.pathname.replace(this.farmId, '') === '/farms//users' || location.pathname.replace(this.farmId, '') === '/farms//users/new';
        if (params.iduser) {
          this.viewUsers = location.pathname.replace(this.farmId, '').replace(params.iduser, '') === '/farms//users//edit';
        }
        if (params.idhub) {
          this.viewHubs = location.pathname.replace(this.farmId, '').replace(params.idhub, '') === '/farms//hubs//details' || 
          location.pathname.replace(this.farmId, '').replace(params.idhub, '') === '/farms//hubs//edit';
        }
        if (params.idsensor) {
          this.viewSensors = location.pathname.replace(this.farmId, '').replace(params.idsensor, '') === '/farms//sensors//edit';
        }
      }
    );
  }

  ngOnInit() {

  }

  goViewGeneral() {
    if (this.usersService.hasPermission('farms.show.measurementStatistics')) {
      this.router.navigate(['/farms', this.farmId, 'details', 'general']);
    }
  }

  goViewAlarms() {
    if (this.usersService.hasPermission('farms.show.alarmHistorics')) {
      this.router.navigate(['/farms', this.farmId, 'alarms']);
    }
  }

  goViewHubs() {
    if (this.usersService.hasPermission('hubs.index.farm')) {
      this.router.navigate(['/farms', this.farmId, 'hubs']);
    }
  }

  goViewSensors() {
    if (this.usersService.hasPermission('sensors.index.farm.hub')) {
      this.router.navigate(['/farms', this.farmId, 'sensors']);
    }
  }

  goViewReport() {
    if (this.usersService.hasPermission('reports.generate')) {
      this.router.navigate(['/farms', this.farmId, 'report-generator']);
    }
  }

  goViewUsers() {
    if (this.usersService.hasPermission('users.index')) {
      this.router.navigate(['/farms', this.farmId, 'users']);
    }
  }

  hasPermission(permission: string) {
    return this.usersService.hasPermission(permission);
  }

}
