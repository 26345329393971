import { Component, OnInit, Injectable, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './../../services/auth/auth.service';
import { Credential } from './../../models/credential';
import { Router } from '@angular/router';
declare let $: any;

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  size: number = window.screen.availWidth;

  credenciales: Credential = {
    username: '',
    password: '',
  };

  error = '';
  loadingform = false;

  constructor(private auth: AuthService, private router: Router) {
    if (localStorage.getItem('token')) {
      this.auth.token();
      const payload: any = this.auth.payload();
      const now = new Date();
      const exp = new Date(payload.exp * 1000);
      if (now < exp) {
        this.router.navigate(['/dashboard']);
      }
    }
  }

  ngOnInit() {

    window.onresize = () => {
      this.size = window.screen.availWidth;
    };

  }

  ngAfterViewInit() {
    $('.tooltips').tooltip();
  }

  autenticar() {

    this.loadingform = true;
    this.auth.getAutentication(this.credenciales).subscribe(
      (data: any) => {
        $('.tooltips').tooltip('hide');
        localStorage.removeItem('token');
        localStorage.removeItem('farmStatus');
        localStorage.removeItem('photo');
        localStorage.removeItem('perfil');
        localStorage.setItem('token', data.access_token);
        this.error = '';
        this.loadingform = false;
        this.router.navigate(['/dashboard']);
      },
      error => {
        switch (error.status) {
          case 404:
            this.error = 'erroralautenticarse';
            break;
          default:
            console.error('Server Error');
            break;
        }
        this.loadingform = false;
      }
    );

  }

  enter(event: any, idelement: string) {
    if (event.keyCode === 13) {
      document.getElementById(idelement).click();
    }
  }

  }


