import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare let $: any;

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.css']
})
export class AddCardComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    // @ts-ignore
    window.$('[data-toggle]').tooltip();
  }

  redirectCreateFarm() {
    $('[data-toggle]').tooltip('hide');
    this.router.navigate(['/farms', 'create']);
  }

}
