import { Component, OnInit } from '@angular/core';
import { FarmsService } from 'src/app/services/farms/farms.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-farm-alarms',
  templateUrl: './farm-alarms.component.html',
  styleUrls: ['./farm-alarms.component.css']
})
export class FarmAlarmsComponent implements OnInit {

  id;
  currentPage;
  loading = true;
  errors = false;

  optionsHubs = {
    value: '',
    text: 'TODOS LOS HUBS',
  };

  optionsSensors = {
    value: '',
    text: 'TODOS LOS SENSORES',
  };

  hubs = [
    {
      id: 2,
      name: 'Hub 2',
      status: 1,
      sensors: []
    },
    {
      id: 1,
      name: 'Hub 1',
      status: 1,
      sensors: [
        {
          id: 1,
          name: 'Sensor 1',
          status: 1
        },
        {
          id: 2,
          name: 'Sensor 2',
          status: 1
        }
      ]
    }
  ];

  sensors: Array<any> [];

  alarms: any = {};

  constructor(private farmsService: FarmsService, private router: Router, private activatedRoute: ActivatedRoute, private usersService: UsersService) {
    this.activatedRoute.params.subscribe(
      params => {
        this.usersService.hasPermissionWithMessage('farms.show.alarmHistorics');
        if (this.usersService.hasPermission('farms.show.alarmHistorics')) {
          this.id = params.id;
          this.getAllHubs();
          this.getHubs();
        }
      }
    );
  }

  ngOnInit() {

  }

  getSensors(hubId) {
    // tslint:disable-next-line:triple-equals
    const hub = this.hubs.find(element => element.id == hubId);
    if (hub) {
      this.sensors = hub.sensors as Array<any>;
      this.optionsHubs.text = hub.name;
      this.optionsHubs.value = '' + hub.id;
      this.optionsSensors.text = 'TODOS LOS SENSORES';
      this.optionsSensors.value = '';
      this.getAlarms();
    }
  }

  getAllHubs() {
    this.optionsHubs.text = 'TODOS LOS HUBS';
    this.optionsHubs.value = '';
    this.optionsSensors.text = 'TODOS LOS SENSORES';
    this.optionsSensors.value = '';
    this.getAlarms();
  }

  selectSensor(sensorId) {
    // tslint:disable-next-line:triple-equals
    const sensor: any = this.sensors.find((element: any) => element.id == sensorId);
    if (sensor) {
      this.optionsSensors.text = sensor.name;
      this.optionsSensors.value = '' + sensor.id;
      this.getAlarms();
    }
  }

  selectSensorAll() {
    this.optionsSensors.text = 'TODOS LOS SENSORES';
    this.optionsSensors.value = '';
    this.getAlarms();
  }

  getHubs() {
    this.farmsService.getHubs(this.id).subscribe(
      data => {
        this.hubs = data.hubs;
      },
      error => {
        console.error(error);
      }
    );
  }

  getLeftPagination() {
    if (this.alarms.page > 1) {
      this.getAlarmsByPage(this.currentPage - 1);
    }
  }

  getRightPagination() {
    if (this.alarms.page < this.alarms.total_pages) {
      this.getAlarmsByPage(this.currentPage + 1);
    }
  }

  getAlarmsByPage(page) {
    this.loading = true;
    this.farmsService.getAlarmsByHubAndSensor(this.id, this.optionsHubs.value, this.optionsSensors.value, page).subscribe(
      data => {
        this.alarms = data;
        this.currentPage = parseInt(data.page, 10);
        this.loading = false;
      },
      error => {
        console.error(error);
        this.loading = false;
        this.errors = true;
      }
    );
  }

  getAlarms() {
    this.loading = true;
    this.farmsService.getAlarmsByHubAndSensor(this.id, this.optionsHubs.value, this.optionsSensors.value).subscribe(
      data => {
        this.alarms = data;
        this.currentPage = parseInt(data.page, 10);
        this.loading = false;
      },
      error => {
        console.error(error);
        this.loading = false;
        this.errors = true;
      }
    );
  }

  hasPermission(permission: string) {
    return this.usersService.hasPermission(permission);
  }
  
}
