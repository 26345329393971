import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { FarmsService } from 'src/app/services/farms/farms.service';
import { UsersService } from 'src/app/services/users/users.service';
declare let $: any;

@Component({
  selector: 'app-create-or-edit-sensor',
  templateUrl: './create-or-edit-sensor.component.html',
  styleUrls: ['./create-or-edit-sensor.component.css']
})
export class CreateOrEditSensorComponent implements OnInit {

  title = 'Crear sensor';
  location = '';
  id;
  sensorId;

  form: any = {
    name: '',
    latitude: null,
    longitude: null,
    hub: '',
    measurement_types: []
  };

  message = {
    type: '',
    message: '',
    status: '',
  };

  hubData: any = {
    id: 0,
    name: '',
    status: 1
  };

  farmsAndHubs = [];
  variablesAvailible = [];
  variablesSelected = [];

  loadingform = false;
  pressLocation = false;

  markerMap: google.maps.Marker = null;
  gmap: google.maps.Map = null;
  @ViewChild('gmap', { static: false }) gmapElement: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private farmsService: FarmsService, private usersService: UsersService) {
    this.activatedRoute.params.subscribe(
      (params: any) => {
        this.id = params.id;
        if (params.idsensor) {
          this.usersService.hasPermissionWithMessage('sensors.edit');
          if (this.usersService.hasPermission('sensors.edit')) {
            this.sensorId = params.idsensor;
            this.title = 'Editar sensor';
            this.getSensor();
          }
        } else {
          this.usersService.hasPermissionWithMessage('sensors.create');
          if (this.usersService.hasPermission('sensors.create')) {
            this.sensorId = null;
            this.title = 'Crear sensor';
            this.getMeasurements();
            this.getFarmsWithHubs();
          }
        }
      });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.sensorId === null) {
      this.initMap();
    }
  }

  getSensor() {
    this.farmsService.getSensor(this.sensorId).subscribe(
      (data: any) => {
        this.form.name = data.name;
        this.form.latitude = data.latitude;
        this.form.longitude = data.longitude;
        this.form.hub = '' + data.hub.id;
        this.form.measurement_types = data.measurement_types;
        this.hubData.id = data.hub.id;
        this.hubData.name = data.hub.name;
        this.hubData.status = data.hub.status;
        this.getMeasurements();
        this.initMap();
      },
      error => {
        console.error(error);
      }
    );
  }

  goListSensors() {
    $('[data-toggle]').tooltip('hide');
    this.router.navigate(['/farms', this.id, 'sensors']);
  }

  getMeasurements() {
    this.farmsService.getListMeasurements().subscribe(
      (data: []) => {
        this.variablesAvailible = data;
        if (this.sensorId) {
          this.form.measurement_types.forEach((measurement_type) => {
            this.addVariable(measurement_type.id);
          });
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  getFarmsWithHubs() {
    this.farmsService.getListHubsForSensors().subscribe(
      (data: []) => {
        this.farmsAndHubs = data;
      },
      error => {
        console.error(error);
      }
    );
  }

  public handleAddressChange(address: Address) {
    const lat = address.geometry.location.lat();
    const lng = address.geometry.location.lng();
    this.gmap.setCenter(new google.maps.LatLng(lat, lng));
    this.gmap.setZoom(18);
    this.markerMap.setPosition(new google.maps.LatLng(lat, lng));
    this.form.latitude = lat;
    this.form.longitude = lng;
  }

  initMap() {

    const self = this;
    let position = null;
    let zoom = null;

    if (this.sensorId) {
      position = new google.maps.LatLng(self.form.latitude, self.form.longitude);
      zoom = 18;
    } else {
      position = new google.maps.LatLng(4.710988599999999, -74.072092);
      zoom = 5;
    }

    const properties = {
      center: position,
      zoom,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      mapTypeControl: false
    };
    this.gmap = new google.maps.Map(this.gmapElement.nativeElement, properties);
    this.markerMap = new google.maps.Marker({ position, map: this.gmap });

    if (this.sensorId) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: position }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results.length > 0) {
            self.location = results[0].formatted_address;
          }
        }
      });
    }

    google.maps.event.addListener(this.gmap, 'place_changed', (event) => {
      // console.log('FROM CHANGED!', event);
    });

    google.maps.event.addListener(this.gmap, 'click', event => {
      self.markerMap.setPosition(new google.maps.LatLng(event.latLng.lat(), event.latLng.lng()));
      self.form.latitude = event.latLng.lat();
      self.form.longitude = event.latLng.lng();
      self.codeLatLng(event.latLng);
    });

  }

  codeLatLng(latlng) {
    const self = this;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({location: latlng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results.length > 0) {
          self.location = results[0].formatted_address;
        }
      }
    });
  }

  addVariable(id) {
    const variableSelected = this.variablesAvailible.find((element) => element.id === id);
    if (variableSelected) {
      this.variablesSelected.push(variableSelected);
      this.variablesAvailible = this.variablesAvailible.filter((element) => element.id !== variableSelected.id);
    } else {
      console.error('Error al agregar usuario - no existe un usuario con id', id);
    }
  }

  removeVariable(id) {
    const variableRemoved = this.variablesSelected.find((element) => element.id === id);
    this.variablesAvailible.push(variableRemoved);
    this.variablesSelected = this.variablesSelected.filter((element) => element.id !== variableRemoved.id);
  }

  clearMessage() {
    this.message = {
      type: '',
      message: '',
      status: '',
    };
  }

  clearForm() {

    const form: any = document.getElementById('form');
    form.reset();

    this.variablesSelected.forEach((element) => {
      this.removeVariable(element.id);
    });

    this.form = {
      name: '',
      latitude: null,
      longitude: null,
      hub: '',
      measurement_types: []
    };

    this.location = '';
    this.pressLocation = false;

  }

  submit() {

    this.loadingform = true;

    this.form.measurement_types = [];
    this.variablesSelected.forEach(element => {
      this.form.measurement_types.push(element.id);
    });

    if (this.sensorId === null) {
      this.createSensor();
    } else {
      this.updateSensor();
    }

  }

  createSensor() {
    this.form.hub = parseInt(this.form.hub, 10);
    console.log('createSensor', this.form);
    this.farmsService.createSensor(this.form).subscribe(
      (data: any) => {
        this.message.type = 'success';
        this.message.message = 'El Sensor se ha creado exitosamente.';
        this.message.status = data.status;
        this.loadingform = false;
        this.clearForm();
      },
      (error: any) => {
        console.error(error);
        this.message.type = 'error';
        if (error.error.message === 'Hub already has a sensor with that name.') {
          this.message.message = 'El hub ya tiene un sensor con ese nombre.';
        } else if (error.error.message === 'User has not permission to use this service!') {
          this.message.message = 'El usuario no tiene permiso para usar este servicio';
        } else {
          this.message.message = error.error.message;
        }
        this.message.status = error.error.status;
        this.loadingform = false;
      }
    );
  }

  updateSensor() {
    this.farmsService.updateSensor(this.sensorId, this.form).subscribe(
      (data: any) => {
        this.message.type = 'success';
        this.message.message = 'El Sensor se ha actualizado exitosamente.';
        this.message.status = data.status;
        this.loadingform = false;
      },
      (error: any) => {
        console.error(error);
        this.message.type = 'error';
        if (error.error.message === 'Hub already has a sensor with that name.') {
          this.message.message = 'Sensor con ese nombre ya existe en este hub.';
        } else if (error.error.message === 'User has not permission to use this service!') {
          this.message.message = 'El usuario no tiene permiso para usar este servicio';
        } else {
          this.message.message = error.error.message;
        }
        this.message.status = error.error.status;
        this.loadingform = false;
      }
    );
  }

}
