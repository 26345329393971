import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation-division-vertical',
  templateUrl: './navigation-division-vertical.component.html',
  styleUrls: ['./navigation-division-vertical.component.css']
})
export class NavigationDivisionVerticalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
