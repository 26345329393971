import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { filter, tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UnauthorizedService implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      filter((event: HttpEvent<any>) => event instanceof HttpResponse),
      tap((resp: HttpResponse<any>) => this.auditEvent(resp)),
      catchError(error => {
        if (error.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('farmStatus');
          localStorage.removeItem('photo');
          localStorage.removeItem('perfil');
          this.router.navigate(['/']);
        }
        return throwError(error);
      })
    );

  }

  /** auditEvent :: solamente se muestran las respuestas positivas o sin errores */
  private auditEvent(resp: HttpResponse<any>) {

    if (resp.status === 401) {
      localStorage.removeItem('token');
      this.router.navigate(['/']);
    }

  }

}
