import { Component, OnInit } from "@angular/core";
import { FarmsService } from "src/app/services/farms/farms.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { UsersService } from "src/app/services/users/users.service";
declare let $: any;

@Component({
  selector: "app-farm-hub-details",
  templateUrl: "./farm-hub-details.component.html",
  styleUrls: ["./farm-hub-details.component.css"],
})
export class FarmHubDetailsComponent implements OnInit {
  id: number;
  hubId: number;
  data: any = {
    last_alert: {
      alarm_date: "2020-01-30",
      alarm_hour: "5:01 PM",
      device_name: "Sensor 1",
      measurement_name: "humedad del suelo",
      alarm_comment: "",
    },
  };

  measurements = [];

  icons = [
    {
      id: 1,
      name: "humedad",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 11px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-humedad.png",
    },
    {
      id: 2,
      name: "temperatura",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 11px; height: 17px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-temperatura.png",
    },
    {
      id: 3,
      name: "luz",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-luz.png",
    },
    {
      id: 4,
      name: "humedad suelo",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-humedad-suelo.png",
    },
    {
      id: 5,
      name: "radiacion",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-radiacion.png",
    },
    {
      id: 6,
      name: "conductancia",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_08.png",
    },
    {
      id: 7,
      name: "ox_disuelto",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_06.png",
    },
    {
      id: 8,
      name: "ox_temperatura",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_05.png",
    },
    {
      id: 9,
      name: "ox_porcentaje",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_07.png",
    },
    {
      id: 10,
      name: "salinidad",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_04.png",
    },
    {
      id: 11,
      name: "bateria",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_03.png",
    },
    {
      id: 12,
      name: "salinidad",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_04.png",
    },
    {
      id: 13,
      name: "ox_corregido",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_06.png",
    },
  ];

  constructor(
    private farmsService: FarmsService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    private usersService: UsersService
  ) {
    this.activatedRoute.params.subscribe((params: any) => {
      this.usersService.hasPermissionWithMessage("hubs.show");
      if (this.usersService.hasPermission("hubs.show")) {
        this.id = params.id;
        this.hubId = params.idhub;
        this.getSummaryHub();
      }
    });
  }

  optionsSensors = {
    value: "",
    text: "Todos los hubs",
  };

  ngOnInit() {}

  getSummaryHub() {
    this.farmsService.getSummaryHub(this.hubId).subscribe(
      (data: any) => {
        this.data = data;
        // this.getAllMeasurements();
        this.measurements = data.measurements;
        this.optionsSensors.text = "Todos los sensores";
        this.optionsSensors.value = "";
      },
      (error) => {
        console.error(error);
      }
    );
  }

  getAllMeasurements() {
    this.farmsService.getMeasurementsBySensor(this.hubId, "").subscribe(
      (data: any) => {
        this.measurements = data;
        this.optionsSensors.text = "Todos los sensores";
        this.optionsSensors.value = "";
      },
      (error) => {
        console.error(error);
      }
    );
  }

  getIcon(id: number = 1) {
    return this.icons.find((elemento) => elemento.id === id) || this.icons[0];
  }

  toGrades(num: number) {
    num = Math.abs(num);
    const grades = parseInt("" + num, 10);
    const minutesTemp = (num - grades) * 60;
    const minutes = parseInt("" + minutesTemp, 10);
    const seconds = parseInt("" + (minutesTemp - minutes) * 60, 10);
    return grades + "°" + minutes + "'" + seconds + "''";
  }

  getMeasurementsBySensor(idsensor, text) {
    this.farmsService.getMeasurementsBySensor(this.hubId, idsensor).subscribe(
      (data: any) => {
        this.measurements = data;
        this.optionsSensors.text = text;
        this.optionsSensors.value = idsensor;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  goListHubs() {
    $("[data-toggle]").tooltip("hide");
    this.router.navigate(["/farms", this.id, "hubs"]);
  }
}
