import { Component, OnInit, Input, TemplateRef, AfterViewInit } from '@angular/core';
import { FullscreenComponent } from './../fullscreen/fullscreen.component';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
declare let $: any;

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit, AfterViewInit {

  @Input() title = '¿Qué finca y HUB quieres gestionar?';
  @Input() link = '';
  @Input() iconBack = true;
  @Input() details: TemplateRef<any>;

  constructor(private location: Location, private router: Router) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    $('[data-toggle]').tooltip();
  }

  goBack() {
    $('[data-toggle]').tooltip('hide');
    // this.location.back();
    this.router.navigate([this.link]);
  }

}
