import { Component, OnInit, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { UsersService } from "src/app/services/users/users.service";
import { Router } from "@angular/router";
declare let $: any;

@Component({
  selector: "app-card-sensor",
  templateUrl: "./card-sensor.component.html",
  styleUrls: ["./card-sensor.component.css"],
})
export class CardSensorComponent implements OnInit {
  @Input() sensor;
  @Input() farmId;

  lastDate = "";
  visibilityOthersSensors = false;

  icons = [
    {
      id: 1,
      name: "humedad",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 11px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-humedad.png",
    },
    {
      id: 2,
      name: "temperatura",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 11px; height: 17px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-temperatura.png",
    },
    {
      id: 3,
      name: "luz",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-luz.png",
    },
    {
      id: 4,
      name: "humedad suelo",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-humedad-suelo.png",
    },
    {
      id: 5,
      name: "radiacion",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/variable-radiacion.png",
    },
    {
      id: 6,
      name: "conductancia",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_08.png",
    },
    {
      id: 7,
      name: "ox_disuelto",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_06.png",
    },
    {
      id: 8,
      name: "ox_temperatura",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_05.png",
    },
    {
      id: 9,
      name: "ox_porcentaje",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_07.png",
    },
    {
      id: 10,
      name: "salinidad",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_04.png",
    },
    {
      id: 11,
      name: "bateria",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_03.png",
    },
    {
      id: 12,
      name: "salinidad",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_04.png",
    },
    {
      id: 13,
      name: "ox_corregido",
      style: this.sanitizer.bypassSecurityTrustStyle(
        "width: 14px; height: 15px; vertical-align: text-top;"
      ),
      src: "./assets/img/iconos_sombra_06.png",
    },
  ];

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    // @ts-ignore
    window.$("[data-toggle=tooltip]").tooltip();

    if (this.sensor.measurementTypes.length > 0) {
      this.lastDate = this.sensor.measurementTypes[0].last_value_date;
    }
  }

  getIcon(id: number = 1) {
    return this.icons.find((elemento) => elemento.id === id) || this.icons[0];
  }

  goEdit() {
    $("[data-toggle=tooltip]").tooltip("hide");
    this.router.navigate([
      "/farms",
      this.farmId,
      "sensors",
      this.sensor.id,
      "edit",
    ]);
  }

  toGrades(num: number) {
    num = Math.abs(num);
    const grades = parseInt("" + num, 10);
    const minutesTemp = (num - grades) * 60;
    const minutes = parseInt("" + minutesTemp, 10);
    const seconds = parseInt("" + (minutesTemp - minutes) * 60, 10);
    return grades + "°" + minutes + "'" + seconds + "''";
  }

  onChangeVisibility() {
    this.visibilityOthersSensors = !this.visibilityOthersSensors;
    $("[data-toggle=tooltip]").tooltip("dispose");
    setTimeout(() => {
      $("[data-toggle=tooltip]").tooltip("update");
    }, 300);
  }

  hasPermission(permission: string) {
    return this.usersService.hasPermission(permission);
  }
}
