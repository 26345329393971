import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Credential } from './../../models/credential';
import { BaseService } from './../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {

  constructor(private http: HttpClient) {
    super(http);
  }

  getAutentication(datos: Credential) {
    const path = '/auth/login';
    const url = environment.service.concat(path);
    return this.http.post(url, datos, {}).pipe(
      map((info: any) => info)
    );
  }

}
