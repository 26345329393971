import { Component, OnInit, OnChanges, NgModule } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FarmsService } from 'src/app/services/farms/farms.service';
import { UsersService } from 'src/app/services/users/users.service';
declare let $: any;

@Component({
  selector: 'app-farm-alarms-config',
  templateUrl: './farm-alarms-config.component.html',
  styleUrls: ['./farm-alarms-config.component.css']
})
export class FarmAlarmsConfigComponent implements OnInit, OnChanges {

  id;
  currentPage;

  creatingAlarm = false;
  optionNewAlarm = false;
  loadingDelete = false;
  newAlarmAdded = false;
  transportPage = false;
  idAlarmAdded = 0;
  infoNewAlarm = '';
  deleteAlarmId = 0;

  alarms: any = {};
  newAlarms = [];
  optionsSensor = [];
  optionsVariable = [];
  optionsReceptors = [];

  optionsRules = ['<', '>'];
  optionsChannels = ['SMS', 'E-MAIL', 'SMS + E-MAIL'];

  newAlarm = {
    status: 1,
    device: '',
    variable: '',
    limit_value: 0,
    alarm_rule: '',
    alarm_channel: '',
    receptor: ''
  };

  constructor(private activatedRoute: ActivatedRoute, private farmsService: FarmsService,  private usersService: UsersService) {
    this.activatedRoute.params.subscribe((params: any) => {
      this.usersService.hasPermissionWithMessage('alarms.index');
      if (this.usersService.hasPermission('alarms.index')) {
        this.id = params.id;
        this.transportPage = true;
        this.getAlarm();
      }
      if (this.usersService.hasPermission('alarms.edit') || this.usersService.hasPermission('alarms.create')) {
        this.getSensors();
        this.getReceptors();
        this.getVariables();
      }
    });
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  filterOptionsViariable(id: number) {
    return this.optionsVariable.filter((element: any) => element.id !== id);
  }

  filterOptionsSensor(variable: number, arrayWhat = '', id = 0) {
    const array =  this.optionsSensor.filter((element: any) => {
      // tslint:disable-next-line:triple-equals
      const meauserement = element.measurementTypes.find(element2 => variable == element2.id);
      if (meauserement) {
        // tslint:disable-next-line:triple-equals
        // return element.id != id;
        return true;
      } else {
        return false;
      }
    });
    if (arrayWhat === 'newAlarms' && id === this.idAlarmAdded) {
      this.newAlarmAdded = false;
    }
    return array;
  }

  getDeviceHubname(id: number) {
    const sensor =  this.optionsSensor.find((element: any) => element.id === id);
    if (sensor) {
      return sensor.name;
    } else {
      return '';
    }
  }

  filterOptionsReceptors(id: number) {
    return this.optionsReceptors.filter((element: any) => element.id !== id);
  }

  filterOptionsRule(rule: string) {
    return this.optionsRules.filter((element) => element !== rule);
  }

  filterOptionChannels(channel: string) {
    return this.optionsChannels.filter((element) => element !== channel.toUpperCase());
  }

  minValueAllowed(id) {
    // tslint:disable-next-line:triple-equals
    const variable = this.optionsVariable.find((element) => element.id == id);
    if (variable) {
      return variable.min_value_allowed;
    } else {
      return 0;
    }
  }

  maxValueAllowed(id) {
    // tslint:disable-next-line:triple-equals
    const variable = this.optionsVariable.find((element) => element.id == id);
    if (variable) {
      return variable.max_value_allowed;
    } else {
      return 1000;
    }
  }

  getAlarm() {
    this.farmsService.getAlarmsByUse(this.id, 1).subscribe(
      (data: any) => {
        this.alarms = data;
        this.currentPage = parseInt(data.page, 10);
      },
      error => {
        console.error(error);
      }
    );
  }

  getReceptors() {
    this.farmsService.getReceptors(this.id).subscribe(
      (data: any) => {
        this.optionsReceptors = data.receptors;
      },
      error => {
        console.error(error);
      }
    );
  }

  getSensors() {
    this.farmsService.getSensorsByUse(this.id).subscribe(
      (data: any) => {
        this.optionsSensor = data;
      },
      error => {
        console.error(error);
      }
    );
  }

  getVariables() {
    this.farmsService.getListMeasurements().subscribe(
      (data: any) => {
        this.optionsVariable = data;
      },
      error => {
        console.error(error);
      }
    );
  }

  updateSensor(alarmId, sensorId, variableId, valueLimite, array) {
    this.farmsService.updateAlarmPatch(alarmId, {
      variable: variableId,
      limit_value: valueLimite,
      device: sensorId
    }).subscribe(
      (data: any) => {
        if (array === 'alarms.data') {
          const alarm = this.alarms.data.find((element) => data.alarm.id === element.id);
          alarm.device.id = data.alarm.device.id;
          alarm.device.name = data.alarm.device.name;
        }
        if (array === 'newAlarms') {
          const alarm = this.newAlarms.find((element) => data.alarm.id === element.id);
          alarm.device.id = data.alarm.device.id;
          alarm.device.name = data.alarm.device.name;
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  updateVariableAndValueLimite(alarmId, variableId, valueLimite, sensorId) {
    this.transportPage = false;
    this.farmsService.updateAlarmPatch(alarmId, {
      variable: variableId,
      limit_value: valueLimite,
      device: sensorId
    }).subscribe(
      data => {
        // nothing
      },
      error => {
        console.error(error);
      }
    );
  }

  updateVariable(alarmId, variableId) {
    this.farmsService.updateAlarmPatch(alarmId, {
      variable: variableId
    }).subscribe(
      data => {
        // nothing
      },
      error => {
        console.error(error);
      }
    );
  }

  updateValueLimite(alarmId, valueLimite) {
    this.farmsService.updateAlarmPatch(alarmId, {
      limit_value: valueLimite
    }).subscribe(
      data => {
        // nothing
      },
      error => {
        console.error(error);
      }
    );
  }

  updateRule(alarmId, rule) {
    this.farmsService.updateAlarmPatch(alarmId, {
      alarm_rule: rule
    }).subscribe(
      data => {
        // nothing
      },
      error => {
        console.error(error);
      }
    );
  }

  updateChannel(alarmId, channel) {
    this.farmsService.updateAlarmPatch(alarmId, {
      alarm_channel: channel
    }).subscribe(
      data => {
        // nothing
      },
      error => {
        console.error(error);
      }
    );
  }

  updateReceptors(alarmId, receptor) {
    this.farmsService.updateAlarmPatch(alarmId, {
      receptor
    }).subscribe(
      data => {
        // nothing
      },
      error => {
        console.error(error);
      }
    );
  }

  updateStatus(alarmId, status) {
    status = status ? 1 : 2 ;
    this.farmsService.updateAlarmPatch(alarmId, {
      status
    }).subscribe(
      data => {
        // nothing
      },
      error => {
        console.error(error);
      }
    );
  }

  createAlarm() {
    this.farmsService.createAlarm({
      status: 1,
      device: 1,
      variable: 1,
      limit_value: 50,
      alarm_rule: '<',
      alarm_channel: 'sms',
      receptor: 1
    }).subscribe(
      data => {

      },
      error => {
        console.error(error);
      }
    );
  }

  updateNewAlarmStatus(status) {
    status = status ? 1 : 0;
    this.newAlarm.status = status;
    this.saveAlarm();
  }

  displayNewalarm() {
    if (this.optionNewAlarm) {
      this.infoNewAlarm = 'Actualmente estás agregando una nueva alerta. Por favor completar el registro';
    }
    this.optionNewAlarm = true;
  }

  closeMessageNewAlarm() {
    this.infoNewAlarm = '';
  }

  cleanNewAlarm() {
    this.newAlarm = {
      status: 1,
      device: '',
      variable: '',
      limit_value: 0,
      alarm_rule: '',
      alarm_channel: '',
      receptor: ''
    };
    this.optionNewAlarm = false;
    this.infoNewAlarm = '';
    const customSwitch: any = document.getElementById('customSwitch');
    customSwitch.checked = true;
  }

  saveAlarm() {

    if (this.creatingAlarm) {
      return;
    }

    let validation = true;
    const alarm = {
      status: 1,
      device: 0,
      variable: 0,
      limit_value: 0,
      alarm_rule: '',
      alarm_channel: '',
      receptor: 0
    };
    alarm.status = this.newAlarm.status;
    const device = parseInt(this.newAlarm.device, 10);
    alarm.device = device;
    if (!device) {
      validation = false;
      // console.error('alarm.device', device);
    }
    const variable = parseInt(this.newAlarm.variable, 10);
    alarm.variable = variable;
    if (!variable) {
      validation = false;
      // console.error('alarm.variable', variable);
    }
    const min = this.minValueAllowed(this.newAlarm.variable);
    const max = this.maxValueAllowed(this.newAlarm.variable);
    alarm.limit_value = this.newAlarm.limit_value;
    if (alarm.limit_value < min || alarm.limit_value > max) {
      validation = false;
      // console.error('alarm.limit_value', alarm.limit_value, min, max);
    }
    alarm.alarm_rule = this.newAlarm.alarm_rule;
    if (alarm.alarm_rule.length === 0) {
      validation = false;
      // console.error('alarm.alarm_rule', alarm.alarm_rule);
    }
    alarm.alarm_channel = this.newAlarm.alarm_channel;
    if (alarm.alarm_channel.length === 0) {
      validation = false;
      // console.error('alarm.alarm_channel', alarm.alarm_channel);
    }
    const receptor = parseInt(this.newAlarm.receptor, 10);
    alarm.receptor = receptor;
    if (!receptor) {
      validation = false;
      // console.error('alarm.receptor', alarm.receptor);
    }

    const array = this.optionsSensor.filter((element: any) => {
      // tslint:disable-next-line:triple-equals
      const meauserement = element.measurementTypes.find(element2 => variable == element2.id);
      if (meauserement) {
        return true;
      } else {
        return false;
      }
    });
    // tslint:disable-next-line:triple-equals
    const device2 = array.find((element) => this.newAlarm.device == element.id);
    if (!device2) {
      this.newAlarm.device = '';
    }

    if (validation) {
      this.creatingAlarm = true;
      this.farmsService.createAlarm(alarm).subscribe(
        (data: any) => {
          this.newAlarms.push(data.alarm);
          this.cleanNewAlarm();
          this.creatingAlarm = false;
          this.newAlarmAdded = true;
          this.idAlarmAdded = data.alarm.id;
        },
        error => {
          console.error(error);
          this.creatingAlarm = false;
        }
      );
    } else {

    }

  }

  getLeftPagination() {
    if (this.alarms.page > 1) {
      this.getAlarmsByPage(this.currentPage - 1);
    }
  }

  getRightPagination() {
    if (this.alarms.page < this.alarms.total_pages) {
      this.getAlarmsByPage(this.currentPage + 1);
    }
  }

  getAlarmsByPage(page) {
    this.farmsService.getAlarmsByUse(this.id, page).subscribe(
      (data: any) => {
        this.alarms = data;
        this.currentPage = parseInt(data.page, 10);
        this.newAlarms = [];
      },
      error => {
        console.error(error);
      }
    );
  }

  preDeleteAlarm(id) {
    this.deleteAlarmId = id;
    $('#exampleModal').modal('show');
  }

  deleteAlarm() {
    this.loadingDelete = true;
    this.farmsService.deleteAlarm(this.deleteAlarmId).subscribe(
      data => {
        $('#exampleModal').modal('hide');
        this.alarms.data = this.alarms.data.filter((element) => element.id !== this.deleteAlarmId);
        this.loadingDelete = false;
      },
      error => {
        console.error(error);
        this.loadingDelete = false;
      }
    );
  }

  getFilterSensors() {
    // a
  }

  hasPermission(permission: string) {
    return this.usersService.hasPermission(permission);
  }

}
