import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { NavigationDivisionVerticalComponent } from './components/shared/navigation-division-vertical/navigation-division-vertical.component';
import { NavigationFullScreenComponent } from './components/shared/navigation-full-screen/navigation-full-screen.component';
import { NavigationHorizontalComponent } from './components/shared/navigation-horizontal/navigation-horizontal.component';
import { FullscreenComponent } from './components/templates/fullscreen/fullscreen.component';
import { HorizontalComponent } from './components/templates/horizontal/horizontal.component';
import { DetailsComponent } from './components/templates/details/details.component';
import { FarmsComponent } from './components/farms/farms.component';
import { FarmSummaryComponent } from './components/farm-summary/farm-summary.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FarmAlarmsComponent } from './components/farm-alarms/farm-alarms.component';
import { FarmGeneralComponent } from './components/farm-general/farm-general.component';
import { FarmAlarmsConfigComponent } from './components/farm-alarms-config/farm-alarms-config.component';
import { UsersComponent } from './components/users/users.component';
import { UserNewComponent } from './components/user-new/user-new.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { ReportGeneratorComponent } from './components/report-generator/report-generator.component';
import { FarmHubsComponent } from './components/farm-hubs/farm-hubs.component';
import { FarmHubDetailsComponent } from './components/farm-hub-details/farm-hub-details.component';
import { FarmSensorsComponent } from './components/farm-sensors/farm-sensors.component';
import { VerifySesionGuard } from './guards/verify-sesion.guard';
import { FarmsCreateComponent } from './components/farms-create/farms-create.component';
import { CreateOrEditHubComponent } from './components/farm-hubs/create-or-edit-hub/create-or-edit-hub.component';
import { CreateOrEditSensorComponent } from './components/farm-sensors/create-or-edit-sensor/create-or-edit-sensor.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'navegacionvertical', component: NavigationDivisionVerticalComponent, canActivate: [VerifySesionGuard] },
  { path: 'navegacionhorizontal', component: NavigationHorizontalComponent, canActivate: [VerifySesionGuard] },
  { path: 'navegacionfullscreen', component: NavigationFullScreenComponent, canActivate: [VerifySesionGuard] },
  { path: 'horizontal', component: HorizontalComponent, canActivate: [VerifySesionGuard] },
  { path: 'fullscreen', component: FullscreenComponent, canActivate: [VerifySesionGuard] },
  { path: 'detalles', component: DetailsComponent, canActivate: [VerifySesionGuard] },
  { path: 'farms/create', component: FarmsCreateComponent, canActivate: [VerifySesionGuard] },
  { path: 'farms/:id/edit', component: FarmsCreateComponent, canActivate: [VerifySesionGuard] },
  { path: 'farms/:id/summary', component: FarmSummaryComponent, canActivate: [VerifySesionGuard] },
  { path: 'farms/:id/alarms', component: FarmAlarmsComponent, canActivate: [VerifySesionGuard] },
  { path: 'farms/:id/alarms/config', component: FarmAlarmsConfigComponent, canActivate: [VerifySesionGuard] },
  { path: 'farms/:id/report-generator', component: ReportGeneratorComponent, canActivate: [VerifySesionGuard]},
  { path: 'farms/:id/sensors', component: FarmSensorsComponent, canActivate: [VerifySesionGuard] },
  { path: 'farms/:id/sensors/create', component: CreateOrEditSensorComponent, canActivate: [VerifySesionGuard] },
  { path: 'farms/:id/sensors/:idsensor/edit', component: CreateOrEditSensorComponent, canActivate: [VerifySesionGuard] },
  { path: 'farms/:id/hubs', component: FarmHubsComponent, canActivate: [VerifySesionGuard]},
  { path: 'farms/:id/hubs/:idhub/details', component: FarmHubDetailsComponent, canActivate: [VerifySesionGuard]},
  { path: 'farms/:id/hubs/:idhub/edit', component: CreateOrEditHubComponent, canActivate: [VerifySesionGuard]},
  { path: 'farms/:id/hubs/create', component: CreateOrEditHubComponent, canActivate: [VerifySesionGuard]},
  { path: 'farms/:id/users', component: UsersComponent, canActivate: [VerifySesionGuard]},
  { path: 'farms/:id/users/new', component: UserNewComponent, canActivate: [VerifySesionGuard]},
  { path: 'farms/:id/users/:iduser/edit', component: UserEditComponent, canActivate: [VerifySesionGuard]},
  { path: 'farms/:id/details', component: DashboardComponent,
    children: [
      { path: 'general', component: FarmGeneralComponent, canActivate: [VerifySesionGuard]},
    ]
  },
  { path: 'dashboard', component: FarmsComponent, canActivate: [VerifySesionGuard] },
  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
