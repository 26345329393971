import { Component, OnInit, TemplateRef, Input } from '@angular/core';

@Component({
  selector: 'app-navigation-full-screen',
  templateUrl: './navigation-full-screen.component.html',
  styleUrls: ['./navigation-full-screen.component.css']
})
export class NavigationFullScreenComponent implements OnInit {

  @Input() templateHeader: TemplateRef<any>;
  @Input() templateBody: TemplateRef<any>;

  constructor() { }

  ngOnInit() {
  }

}
