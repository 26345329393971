import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users/users.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  currentPage = 1;
  limit = '';
  data: any = {};
  user: any = {};
  id;
  imgAdmin = false;

  constructor(private usersService: UsersService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(
      (params: any) => {
        this.usersService.hasPermissionWithMessage('users.index');
        if (this.usersService.hasPermission('users.index')) {
          this.id = params.id;
        }
      });
  }

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.imgAdmin = false;
    this.usersService.getUsers(this.currentPage, this.limit).subscribe(
      data => {
        this.data = data;
        if (data.data.length > 0) {
          this.user = data.data[0];
          this.imgAdmin = true;
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  getLeftPagination() {
    if (this.data.page > 1) {
      this.currentPage -= 1;
      this.getUsers();
    }
  }

  getRightPagination() {
    if (this.data.page < this.data.total_pages) {
      this.currentPage += 1;
      this.getUsers();
    }
  }

  getPhotoByToken(imgUrl) {
    // return environment.service + '/users/' + imgUrl + '?bearer=' + localStorage.token;
    return environment.service + '/users/' + imgUrl;
  }

  async getPhoto(id) {
    let image;
    await this.usersService.getPhoto(id).subscribe(
      (data: any) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        image = reader.result;
      }, false);
      if (data) {
        reader.readAsDataURL(data);
      }
    },
    error => {
      console.error(error);
    });
    return image;
  }

  hasPermission(permission: string) {
    return this.usersService.hasPermission(permission);
  }
}
