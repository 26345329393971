import { Pipe, PipeTransform, OnDestroy, ChangeDetectorRef, WrappedValue } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UrlHelperService } from '../services/urlHelper/url-helper.service';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'urlSecureBlob',
  pure: false
})
export class UrlSecureBlobPipe implements PipeTransform, OnDestroy {

  private latestValue: any = null;
  private latestReturnedValue: any = null;
  private subscription: Subscription = null;
  private obj: Observable<any> = null;

  private previousUrl: string;
  private resultt: BehaviorSubject<any> = new BehaviorSubject(null);
  private result: Observable<any> = this.resultt.asObservable();
  private internalSubscription: Subscription = null;

  constructor(
    private ref: ChangeDetectorRef,
    private urlHelperService: UrlHelperService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.dispose();
    }
  }

  transform(url: string): any {
    const obj = this.internalTransform(url);
    return this.asyncTrasnform(obj);
  }

  private internalTransform(url: string): Observable<any> {
    if (!url) {
      return this.result;
    }

    if (this.previousUrl !== url) {
      this.previousUrl = url;
      this.internalSubscription = this.urlHelperService.get(url).subscribe(m => {
        const sanitized = this.sanitizer.bypassSecurityTrustUrl(m);
        this.resultt.next(sanitized);
      });
    }

    return this.result;
  }

  private asyncTrasnform(obj: Observable<any>): any {
    if (!this.obj) {
      if (obj) {
        this._subscribe(obj);
      }
      this.latestReturnedValue = this.latestValue;
      return this.latestValue;
    }
    if (obj !== this.obj) {
      this.dispose();
      return this.asyncTrasnform(obj);
    }
    if (this.latestValue === this.latestReturnedValue) {
      return this.latestReturnedValue;
    }
    this.latestReturnedValue = this.latestValue;
    return WrappedValue.wrap(this.latestValue);
  }

  private _subscribe(obj: Observable<any>) {
    const thiss = this;
    this.obj = obj;

    this.subscription = obj.subscribe({
      next:
        (value) => {
          return thiss._updateLatestValue(obj, value);
        },
        error: (e: any) => {
          throw e;
        }
    });
  }

  private dispose() {
    this.subscription.unsubscribe();
    this.internalSubscription.unsubscribe();
    this.internalSubscription = null;
    this.latestValue = null;
    this.latestReturnedValue = null;
    this.subscription = null;
    this.obj = null;
  }

  private _updateLatestValue(async: any, value: object) {
    if (async === this.obj) {
      this.latestValue = value;
      this.ref.markForCheck();
    }
  }

}
