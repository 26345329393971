import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Token } from './../../models/token';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  $token: Token = {
    header: '',
    payload: '',
    verifySignature: '',
  };

  constructor(protected httpClient: HttpClient) {
    this.token();
  }

  query(query: string, method: string = 'get', data: any = {}, download = false) {
    const url = environment.service.concat(query);
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
    const options: any = { headers };
    if (download) {
      options.responseType = 'blob';
    }
    switch (method) {
      case 'get':
        return this.httpClient.get(url, options);
        break;
      case 'post':
        return this.httpClient.post(url, data, { headers });
        break;
      case 'put':
        return this.httpClient.put(url, data, { headers });
        break;
      case 'delete':
        return this.httpClient.delete(url, { headers });
        break;
      case 'patch':
        return this.httpClient.patch(url, data, { headers });
        break;
    }
  }

  token() {
    if (localStorage.token) {
      [this.$token.header, this.$token.payload, this.$token.verifySignature] = localStorage.token.split('.', 3);
    }
  }

  payload() {
    return JSON.parse(atob(this.$token.payload));
  }

}
