import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseService } from './../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class FarmsService extends BaseService {

  constructor(private http: HttpClient) {
    super(http);
  }

  public getFarms(page = '') {
    const endpoint = '/farms?limit=9&page=' + page;
    return this.query(endpoint, 'get').pipe(
      map((info: any) => info)
    );
  }

  public getFarmsNoPaginate() {
    const endpoint = '/farms/hubs';
    return this.query(endpoint, 'get');
  }

  public getSummaryHub(id) {
    const endpoint = '/hubs/' + id + '/summary';
    return this.query(endpoint, 'get').pipe(
      map((info: any) => info)
    );
  }

  public getMeasurementsBySensor(hubId, sensorId = '') {
    const endpoint = '/hubs/' + hubId + '/summary/filter?sensor=' + sensorId;
    return this.query(endpoint, 'get').pipe(
      map((info: any) => info)
    );
  }

  public getSummaryFarm(id) {
    const endpoint = '/farms/' + id + '/summary';
    return this.query(endpoint, 'get').pipe(
      map((info: any) => info)
    );
  }

  public getMeasurements(farm, hubId = '', dateId = '') {
    const endpoint = '/farms/' + farm + '/summary/filter?hub_id=' + hubId + '&date=' + dateId ;
    return this.query(endpoint, 'get').pipe(
      map((info: any) => info)
    );
  }

  public getAlarms(farmId, last = '') {
    const endpoint = '/farms/' + farmId + '/alarms?limit=' + last;
    return this.query(endpoint, 'get').pipe(
      map((info: any) => info.data)
    );
  }

  public getAlarmsByHubAndSensor(farmId, hubId = '', sensorId = '', page = '') {
    const endpoint = '/farms/' + farmId + '/alarms?hub=' + hubId + '&sensor=' + sensorId + '&page=' + page;
    return this.query(endpoint, 'get').pipe(
      map((info: any) => info)
    );
  }

  public getHubs(farmId) {
    const endpoint = '/farms/' + farmId + '/hubs';
    return this.query(endpoint, 'get').pipe(
      map((info: any) => info)
    );
  }

  public getHubsByFarm(farmId, pos = 1) {
    const endpoint = `/hubs/byFarm?limit=9&farm=${farmId}&page=${pos}`;
    return this.query(endpoint, 'get').pipe(
      map((info: any) => info)
    );
  }

  public getStatusSensors(farmId, hubId = '') {
    const endpoint = '/farms/' + farmId + '/sensorStatus?hub_id=' + hubId;
    return this.query(endpoint, 'get').pipe(
      map((info: any) => info)
    );
  }

  // public getMeasurementTypes() {
  //   const endpoint = '/measurementTypes';
  //   return this.query(endpoint, 'get');
  // }

  public getMeasurementStatistics(farmId, measurementType = '', date = '') {
    const endpoint = '/farms/' + farmId + '/mesasurementStatistics?measurementType=' + measurementType + '&date=' + date;
    return this.query(endpoint, 'get');
  }

  public getReport(type, format, measurementType = '', date = '') {
    const endpoint = '/reports?type=' + type + '&format=' + format + '&measurementType=' + measurementType + '&date=' + date;
    return this.query(endpoint, 'get', {}, true);
  }

  public getStatusFarm(idFarm) {
    const endpoint = '/farms/' + idFarm + '/status';
    return this.query(endpoint, 'get');
  }

  public getAlarmsByUse(farmID, page: number = 1) {
    const endpoint = '/alarms/byFarm?page=' + page + '&farm=' + farmID;
    return this.query(endpoint, 'get');
  }

  public getSensorsByUse(farmId) {
    const endpoint = '/sensors/byFarm?farm=' + farmId;
    return this.query(endpoint, 'get');
  }

  public getReceptors(farmId) {
    const endpoint = '/users/receptors/alarms?farm=' + farmId;
    return this.query(endpoint, 'get');
  }

  public updateAlarmPatch(alarm, data) {
    const endpoint = '/alarms/' + alarm;
    return this.query(endpoint, 'patch', data);
  }

  createAlarm(data) {
    const endpoint = '/alarms';
    return this.query(endpoint, 'post', data);
  }

  deleteAlarm(id) {
    const endpoint = '/alarms/' + id;
    return this.query(endpoint, 'delete');
  }

  report(type, date, farmId, measurementType) {
    const endpoint = `/reports?type=${type}&farm=${farmId}&date=${date}&measurementType=${measurementType}`;
    return this.query(endpoint, 'get');
  }

  // public getHubsNoPaginate(farmId) {
  //   const endpoint = '/hubs/byFarm/noPaginate?farm=' + farmId;
  //   return this.query(endpoint, 'get').pipe(
  //     map((info: any) => info)
  //   );
  // }

  public getSensors(farmId, hub = '', pos = '') {
    const endpoint = `/sensors/byFarmHubs?limit=9&farm=${farmId}&hub=${hub}&page=${pos}`;
    return this.query(endpoint, 'get').pipe(
      map((info: any) => info)
    );
  }

  public createFarm(data) {
    const endpoint = '/farms';
    return this.query(endpoint, 'post', data);
  }

  public getFarm(id) {
    const endpoint = `/farms/${id}/details`;
    return this.query(endpoint, 'get');
  }

  public updateFarm(id, data) {
    const endpoint = '/farms/' + id;
    return this.query(endpoint, 'put', data);
  }

  public createHub(data) {
    const endpoint = '/hubs';
    return this.query(endpoint, 'post', data);
  }

  public getHub(id) {
    const endpoint = `/hubs/${id}`;
    return this.query(endpoint, 'get');
  }

  public updateHub(id, data) {
    const endpoint = '/hubs/' + id;
    return this.query(endpoint, 'put', data);
  }

  public getListMeasurements() {
    const endpoint = '/measurementTypes/noPaginate';
    return this.query(endpoint, 'get');
  }
  
  public getListHubsForSensors() {
    const endpoint = '/hubs/list/sensors';
    return this.query(endpoint, 'get');
  }

  public createSensor(data) {
    const endpoint = '/sensors';
    return this.query(endpoint, 'post', data);
  }

  public getSensor(id) {
    const endpoint = `/sensors/${id}/details`;
    return this.query(endpoint, 'get');
  }

  public updateSensor(id, data) {
    const endpoint = '/sensors/' + id;
    return this.query(endpoint, 'put', data);
  }

  deleteFarm(id) {
    const endpoint = '/farms/' + id;
    return this.query(endpoint, 'delete');
  }
  
  reactiveFarm(id) {
    const endpoint = '/farms/' + id + '/reactivate';
    return this.query(endpoint, 'post');
  }

}
