import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
// @ts-ignore
import { } from '@types/googlemaps';
import { UsersService } from 'src/app/services/users/users.service';
import { FarmsService } from 'src/app/services/farms/farms.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-farms-create',
  templateUrl: './farms-create.component.html',
  styleUrls: ['./farms-create.component.css']
})
export class FarmsCreateComponent implements OnInit, AfterViewInit {

  title = 'Crear una finca';
  location = '';
  id = null;
  
  farm: any = {
    id: 0
  };

  form: any = {
    id: null,
    name: '',
    latitude: null,
    longitude: null,
    users: []
  };

  message = {
    type: '',
    message: '',
    status: '',
  };

  usersAvailible = [];
  usersSelected = [];
  loadingform = false;

  markerMap: google.maps.Marker = null;
  gmap: google.maps.Map = null;
  @ViewChild('gmap', { static: false }) gmapElement: any;

  constructor(private usersService: UsersService, private farmsService: FarmsService,
              private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(
      (params: any) => {
        if (params.id) {
          this.usersService.hasPermissionWithMessage('farms.edit');
          if (this.usersService.hasPermission('farms.edit')) {
            this.id = params.id;
            this.title = 'Editar finca';
            this.getFarm();
          }
        } else {
          this.usersService.hasPermissionWithMessage('farms.create');
          if (this.usersService.hasPermission('farms.create')) {
            this.id = null;
            this.title = 'Crear una finca';
            this.getUsers();
          }
        }
      });
  }

  public handleAddressChange(address: Address) {
    const lat = address.geometry.location.lat();
    const lng = address.geometry.location.lng();
    this.gmap.setCenter(new google.maps.LatLng(lat, lng));
    this.gmap.setZoom(18);
    this.markerMap.setPosition(new google.maps.LatLng(lat, lng));
    this.form.latitude = lat;
    this.form.longitude = lng;
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    if (this.id === null) {
      this.initMap();
    }
    // if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(function(position) {
    //         self.getCurrenUserCountry(position.coords.latitude, position.coords.longitude);
    //     },  function() {}, { enableHighAccuracy: true, timeout: 20000, maximumAge: 0 });
    // }
  }

  initMap() {

    const self = this;
    let position = null;
    let zoom = null;

    if (this.id) {
      position = new google.maps.LatLng(self.form.latitude, self.form.longitude);
      zoom = 18;
    } else {
      position = new google.maps.LatLng(4.710988599999999, -74.072092);
      zoom = 5;
    }

    const properties = {
      center: position,
      zoom,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      mapTypeControl: false
    };
    this.gmap = new google.maps.Map(this.gmapElement.nativeElement, properties);
    this.markerMap = new google.maps.Marker({ position, map: this.gmap });

    if (this.id) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: position }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results.length > 0) {
            self.location = results[0].formatted_address;
          }
        }
      });
    }

    google.maps.event.addListener(this.gmap, 'place_changed', (event) => {
      // console.log('FROM CHANGED!', event);
    });

    google.maps.event.addListener(this.gmap, 'click', event => {
      self.markerMap.setPosition(new google.maps.LatLng(event.latLng.lat(), event.latLng.lng()));
      self.form.latitude = event.latLng.lat();
      self.form.longitude = event.latLng.lng();
      self.codeLatLng(event.latLng);
    });

  }

  codeLatLng(latlng) {
    const self = this;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({location: latlng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results.length > 0) {
          self.location = results[0].formatted_address;
        }
      }
    });
  }

  addUser(id) {
    const usersSelected = this.usersAvailible.find((element) => element.id === id);
    if (usersSelected) {
      this.usersSelected.push(usersSelected);
      this.usersAvailible = this.usersAvailible.filter((element) => element.id !== usersSelected.id);
    } else {
      console.error('Error al agregar usuario - no existe un usuario con id', id);
    }
  }

  removeUser(id) {
    const usersRemoved = this.usersSelected.find((element) => element.id === id);
    this.usersAvailible.push(usersRemoved);
    this.usersSelected = this.usersSelected.filter((element) => element.id !== usersRemoved.id);
  }

  getUsers() {
    this.usersService.getUsersAdministradores().subscribe(
      data => {
        this.usersAvailible = data as [];
        if (this.id) {
          this.form.users.forEach((user) => {
            this.addUser(user.id);
          });
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  getFarm() {
    this.farmsService.getFarm(this.id).subscribe(
      data => {
        this.form = data;
        this.getUsers();
        this.initMap();
      },
      error => {
        console.error(error);
      }
    );
  }

  submit() {

    this.loadingform = true;

    this.form.users = [];
    this.usersSelected.forEach(element => {
      this.form.users.push(element.id);
    });

    if (this.id === null) {
      this.createFarm();
    } else {
      this.updateFarm();
    }

  }

  clearMessage() {
    this.message = {
      type: '',
      message: '',
      status: '',
    };
  }

  clearForm() {

    const form: any = document.getElementById('form');
    form.reset();

    this.usersSelected.forEach((element) => {
      this.removeUser(element.id);
    });

    this.form = {
      name: '',
      latitude: null,
      longitude: null,
      users: []
    };

    this.location = '';

  }

  createFarm() {
    this.farmsService.createFarm(this.form).subscribe(
      (data: any) => {
        this.farm = data.farm;
        this.message.type = 'success';
        this.message.message = 'La finca se ha creado exitosamente.';
        this.message.status = data.status;
        this.loadingform = false;
        this.clearForm();
      },
      (error: any) => {
        console.error(error);
        this.message.type = 'error';
        if (error.error.message === 'This name is already been registered.') {
          this.message.message = 'El nombre de la finca ya ha sido registrado.';
        } else if (error.error.message === 'User has not permission to use this service!') {
          this.message.message = 'El usuario no tiene permiso para usar este servicio';
        } else {
          this.message.message = error.error.message;
        }
        this.message.status = error.error.status;
        this.loadingform = false;
      }
    );
  }

  updateFarm() {
    this.farmsService.updateFarm(this.id, this.form).subscribe(
      (data: any) => {
        this.message.type = 'success';
        this.message.message = 'La finca se ha actualizado exitosamente.';
        this.message.status = data.status;
        this.loadingform = false;
      },
      (error: any) => {
        console.error(error);
        this.message.type = 'error';
        if (error.error.message === 'This name is already been registered.') {
          this.message.message = 'El nombre de la finca ya ha sido registrado.';
        } else if (error.error.message === 'User has not permission to use this service!') {
          this.message.message = 'El usuario no tiene permiso para usar este servicio';
        } else {
          this.message.message = error.error.message;
        }
        this.message.status = error.error.status;
        this.loadingform = false;
      }
    );
  }

}
