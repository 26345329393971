import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FarmsService } from "src/app/services/farms/farms.service";
import { TimeUnit } from "chart.js";
import * as moment from "moment";
import * as Chart from "chart.js";
declare let $: any;

@Component({
  selector: "app-farm-general",
  templateUrl: "./farm-general.component.html",
  styleUrls: ["./farm-general.component.css"],
})
export class FarmGeneralComponent implements OnInit, AfterViewInit {
  farmId;
  chart = null;
  dataGraph = null;
  optionsDataGraph = null;

  measurementsTypes = [];

  icons = [
    {
      id: 1,
      name: "humedad",
      src: "./assets/img/humedad.png",
      class: "img-1",
      class2: "img-1-aux",
    },
    {
      id: 2,
      name: "temperatura",
      src: "./assets/img/temperatura.png",
      class: "img-2",
      class2: "img-2-aux",
    },
    {
      id: 3,
      name: "luz",
      src: "./assets/img/luz.png",
      class: "img-3",
      class2: "img-3-aux",
    },
    {
      id: 4,
      name: "humedad suelo",
      src: "./assets/img/humedad-suelo.png",
      class: "img-4",
      class2: "img-4-aux",
    },
    {
      id: 5,
      name: "radiacion",
      src: "./assets/img/radiacion.png",
      class: "img-5",
      class2: "img-5-aux",
    },
    {
      id: 6,
      name: "conductancia",
      src: "./assets/img/iconos_sombra_08.png",
      class: "img-6",
      class2: "img-6-aux",
    },
    {
      id: 7,
      name: "ox_disuelto",
      src: "./assets/img/iconos_sombra_06.png",
      class: "img-7",
      class2: "img-7-aux",
    },
    {
      id: 8,
      name: "ox_temperatura",
      src: "./assets/img/iconos_sombra_05.png",
      class: "img-8",
      class2: "img-8-aux",
    },
    {
      id: 9,
      name: "ox_porcentaje",
      src: "./assets/img/iconos_sombra_07.png",
      class: "img-9",
      class2: "img-9-aux",
    },
    {
      id: 10,
      name: "salinidad",
      src: "./assets/img/iconos_sombra_04.png",
      class: "img-10",
      class2: "img-10-aux",
    },
    {
      id: 11,
      name: "bateria",
      src: "./assets/img/iconos_sombra_03.png",
      class: "img-11",
      class2: "img-11-aux",
    },
    {
      id: 12,
      name: "salinidad",
      src: "./assets/img/iconos_sombra_04.png",
      class: "img-12",
      class2: "img-12-aux",
    },
    {
      id: 13,
      name: "ox_corregido",
      src: "./assets/img/iconos_sombra_06.png",
      class: "img-13",
      class2: "img-13-aux",
    },
  ];

  measurementCurrent = {
    value: 0,
    text: "",
  };

  frecuencies = [
    { id: 1, text: "Hoy" },
    { id: 2, text: "Ayer" },
    { id: 3, text: "Última semana" },
    { id: 4, text: "Último mes" },
    { id: 5, text: "Últimos seis meses" },
  ];

  optionsFrecuency = {
    value: 4,
    text: "Último mes",
  };

  measurementStatistics: any = {};

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private farmsService: FarmsService
  ) {
    (this.router.routerState as any)
      .parent(this.activatedRoute)
      .params.subscribe((params) => {
        this.farmId = params.id;
        this.getMeauserementsTypes();
      });
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  getIcon(id: number = 1) {
    return this.icons.find((elemento) => elemento.id === id) || this.icons[0];
  }

  goSummary() {
    $("[data-toggle]").tooltip("hide");
    this.router.navigate(["/farms", this.farmId, "summary"]);
  }

  getMeauserementsTypes() {
    this.farmsService.getListMeasurements().subscribe(
      (data: any) => {
        this.measurementsTypes = data;
        if (this.measurementsTypes.length > 0) {
          this.selectedMeasurement(this.measurementsTypes[0].id);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  selectedMeasurement(id) {
    const measurementType = this.measurementsTypes.find(
      (element) => element.id === id
    );
    if (measurementType) {
      this.farmsService
        .getMeasurementStatistics(
          this.farmId,
          measurementType.id,
          "" + this.optionsFrecuency.value
        )
        .subscribe(
          (data: any) => {
            this.measurementStatistics = data.values;
            this.measurementCurrent.value = measurementType.id;
            this.measurementCurrent.text = measurementType.name;
            this.generateGraph();
          },
          (error) => {
            console.error(error);
          }
        );
    }
  }

  selectedFrecuency(id) {
    const frecuency = this.frecuencies.find((element) => element.id === id);
    if (frecuency) {
      this.farmsService
        .getMeasurementStatistics(
          this.farmId,
          "" + this.measurementCurrent.value,
          "" + frecuency.id
        )
        .subscribe(
          (data: any) => {
            this.measurementStatistics = data.values;
            this.optionsFrecuency.value = frecuency.id;
            this.optionsFrecuency.text = frecuency.text;
            this.generateGraph();
          },
          (error) => {
            console.error(error);
          }
        );
    }
  }

  toTypeOration(text = "") {
    if (typeof text === "string") {
      return (
        text.charAt(0).toUpperCase() + text.slice(1, text.length).toLowerCase()
      );
    }
    return text;
  }

  extract(temp1, time = []) {
    let newData = [];
    const start = moment(new Date(temp1[0].date));
    const end = moment(new Date(temp1[temp1.length - 1].date));
    const register =
      time.length > 0
        ? time
        : [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
            19, 20, 21, 22, 23,
          ];
    while (start.isSameOrBefore(end)) {
      let days = temp1.filter((element) => {
        return (
          start.format("YYYY-MM-DD") ==
          moment(element.date).format("YYYY-MM-DD")
        );
      });
      for (let i = 0; i < register.length; i++) {
        const hours = days.filter((element) => {
          return moment(element.date).hour() == register[i];
        });
        if (hours.length > 0) {
          // newData.push(hours[0]);
          let sum = 0;
          hours.forEach((element) => {
            sum += element.value;
          });
          let ave = sum / hours.length;
          ave = parseFloat(ave.toFixed(1));
          newData.push({
            value: ave,
            date: moment(new Date(hours[0].date)).format("YYYY-MM-DD HH:00:00"),
          });
        }
        days = days.slice(hours.length);
      }
      start.add(1, "days");
      temp1 = temp1.slice(days.length);
    }
    return newData;
  }

  translateMonth(month) {
    var result = month;

    switch (month) {
      case "Jan":
        result = "Ene";
        break;
      case "Feb":
        result = "Feb";
        break;
      case "Mar":
        result = "Mar";
        break;
      case "Apr":
        result = "Abr";
        break;
      case "May":
        result = "May";
        break;
      case "Jun":
        result = "Jun";
        break;
      case "Jul":
        result = "Jul";
        break;
      case "Aug":
        result = "Ago";
        break;
      case "Sep":
        result = "Sep";
        break;
      case "Oct":
        result = "Oct";
        break;
      case "Nov":
        result = "Nov";
        break;
      case "Dec":
        result = "Dic";
        break;
    }

    return result;
  }

  translateLabel(label) {
    var month = label.match(/Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Nov|Dec/g);

    if (!month) {
      return label;
    }

    const translation = this.translateMonth(month[0]);
    return label.replace(month, translation, "g");
  }

  getScale() {
    return this.measurementStatistics.scale || "";
  }

  generateGraph() {
    if (this.measurementStatistics) {
      const labels = [];
      const showdata = [];
      const data = this.measurementStatistics.data;
      // const data = this.extract(this.measurementStatistics.data); // 562/6802 = 8.26%
      // const data = this.extract(this.measurementStatistics.data, [6,7,8,9,10,11,12,13,14,15,16,17,18]);
      // const data = this.extract(this.measurementStatistics.data, [0,2,4,6,8,10,12,14,16,18,20,22]); // 281/6802 = 4.16%
      // const data = this.extract(this.measurementStatistics.data, [0,3,6,9,12,15,18,21]); // 187/6802 = 2.75%
      // const data = this.extract(this.measurementStatistics.data, [0,4,8,12,16,20]); // 140/6802 = 2.05%

      let defaultAxesx: TimeUnit = "day"; // month, day, hour
      let defaultWidthLine = 2.3;
      const borderRadius = this.measurementStatistics.data == 1 ? 3 : 0;

      if (
        this.optionsFrecuency.value === 1 ||
        this.optionsFrecuency.value === 2
      ) {
        defaultAxesx = "hour";
        defaultWidthLine = 3;
      }

      /*const data = [
        {value: 9, date: '2020-02-23 04:00:00'},
        {value: 10, date: '2020-02-24 04:00:00'},
        {value: 23, date: '2020-02-25 04:00:25'},
        {value: 8.5, date: '2020-02-25 08:00:00'},
        {value: 6, date: '2020-02-25 14:00:00'},
        {value: 13, date: '2020-02-25 18:00:00'},
        {value: 11, date: '2020-02-26 04:00:00'},
      ];*/

      data.map((ondata) => {
        labels.push(ondata.value);
        showdata.push(ondata.date);
      });

      if (this.chart) {
        this.dataGraph.labels = showdata;
        this.dataGraph.datasets[0].data = labels;
        this.dataGraph.datasets[0].borderWidth = defaultWidthLine;
        this.dataGraph.datasets[0].label = this.measurementCurrent.text;
        this.optionsDataGraph.scales.xAxes[0].time.unit = defaultAxesx;
        this.chart.options.scales.yAxes[0].scaleLabel.labelString =
          "" + this.measurementStatistics.scale_string;
        this.chart.options.elements.point.radius = borderRadius;
        this.chart.update();
      } else {
        this.dataGraph = {
          labels: showdata,
          datasets: [
            {
              label: this.measurementCurrent.text,
              backgroundColor: "rgba(230, 244, 253, 0.55)", // #e6f4fd
              borderColor: "rgba(145, 220, 100, 0.65)", // #91dc64
              data: labels,
              pointHoverBackgroundColor: "rgb(255, 99, 132)",
              pointHoverBorderColor: "rgb(255, 99, 132)",
              pointHoverRadius: 3,
              borderWidth: defaultWidthLine, // default 3
            },
          ],
        };

        this.optionsDataGraph = {
          responsive: false,
          scales: {
            xAxes: [
              {
                type: "time",
                // distribution: 'series',
                time: {
                  unit: defaultAxesx,
                  displayFormats: {
                    day: "DD MMM", // DD/MM/YYYY
                  },
                  tooltipFormat: "DD/MM/YYYY h:mm:ss a",
                },
                ticks: {
                  // Include a dollar sign in the ticks
                  callback: (value, index, values) => {
                    return this.translateLabel(value);
                  },
                },
                scaleLabel: { display: true, labelString: "Tiempo" },
                gridLines: {
                  display: true,
                  color: "#bfbfbf7a",
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "" + this.measurementStatistics.scale_string,
                }, // Valores
                gridLines: {
                  display: true,
                  color: "#bfbfbf7a",
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              /*title: (tooltipItem: any, data) => {
                const a = tooltipItem[0].label.split('-');
                a.reverse();
                return a.join('/');
              },*/
              label: (tooltipItem: any, data) => {
                return (
                  data.datasets[0].label +
                  ": " +
                  tooltipItem.value +
                  this.getScale()
                );
              },
            },
            mode: "index", // point, single, nearest, index, x
            enabled: true,
            intersect: false,
            position: "nearest",
          },
          elements: {
            point: {
              radius: borderRadius,
              pointStyle: "circle",
            },
          },
          animation: {
            duration: 1000,
          },
          hover: {
            animationDuration: 100,
            mode: "index",
            intersect: false,
          },
        };

        const ctx = (document.getElementById("myChart") as any).getContext(
          "2d"
        );

        this.chart = new Chart(ctx, {
          // The type of chart we want to create
          type: "line",

          // The data for our dataset
          data: this.dataGraph,

          // Configuration options go here
          options: this.optionsDataGraph,
        });
      }
    }
  }
}
