import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ResponsiveModule } from 'ngx-responsive';
import { ChartsModule } from 'ng2-charts';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { NavigationDivisionVerticalComponent } from './components/shared/navigation-division-vertical/navigation-division-vertical.component';
import { NavigationFullScreenComponent } from './components/shared/navigation-full-screen/navigation-full-screen.component';
import { NavigationHorizontalComponent } from './components/shared/navigation-horizontal/navigation-horizontal.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { PerfilComponent } from './components/shared/perfil/perfil.component';
import { FullscreenComponent } from './components/templates/fullscreen/fullscreen.component';
import { NavbarMinimalComponent } from './components/shared/navbar-minimal/navbar-minimal.component';
import { HorizontalComponent } from './components/templates/horizontal/horizontal.component';
import { DetailsComponent } from './components/templates/details/details.component';
import { FarmsComponent } from './components/farms/farms.component';
import { CardFarmComponent } from './components/shared/card-farm/card-farm.component';
import { AddCardComponent } from './components/shared/add-card/add-card.component';
import { FarmSummaryComponent } from './components/farm-summary/farm-summary.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FarmAlarmsComponent } from './components/farm-alarms/farm-alarms.component';
import { FarmGeneralComponent } from './components/farm-general/farm-general.component';
import { MenuNavigationComponent } from './components/shared/menu-navigation/menu-navigation.component';
import { SensorsStateComponent } from './components/shared/sensors-state/sensors-state.component';
import { FarmAlarmsConfigComponent } from './components/farm-alarms-config/farm-alarms-config.component';
import { UsersComponent } from './components/users/users.component';
import { UrlSecureBlobPipe } from './pipes/url-secure-blob.pipe';
import { GetPhotoPerfilComponent } from './components/shared/get-photo-perfil/get-photo-perfil.component';
import { UserNewComponent } from './components/user-new/user-new.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { ReportGeneratorComponent } from './components/report-generator/report-generator.component';
import { FarmHubsComponent } from './components/farm-hubs/farm-hubs.component';
import { CardHubComponent } from './components/shared/card-hub/card-hub.component';
import { FarmHubDetailsComponent } from './components/farm-hub-details/farm-hub-details.component';
import { UnauthorizedService } from './services/unauthorized/unauthorized.service';
import { FarmSensorsComponent } from './components/farm-sensors/farm-sensors.component';
import { CardSensorComponent } from './components/shared/card-sensor/card-sensor.component';
import { FarmsCreateComponent } from './components/farms-create/farms-create.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { CreateOrEditHubComponent } from './components/farm-hubs/create-or-edit-hub/create-or-edit-hub.component';
import { CreateOrEditSensorComponent } from './components/farm-sensors/create-or-edit-sensor/create-or-edit-sensor.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavigationDivisionVerticalComponent,
    NavigationFullScreenComponent,
    NavigationHorizontalComponent,
    NavbarComponent,
    PerfilComponent,
    FullscreenComponent,
    NavbarMinimalComponent,
    HorizontalComponent,
    DetailsComponent,
    FarmsComponent,
    CardFarmComponent,
    AddCardComponent,
    FarmSummaryComponent,
    DashboardComponent,
    FarmAlarmsComponent,
    FarmGeneralComponent,
    MenuNavigationComponent,
    SensorsStateComponent,
    FarmAlarmsConfigComponent,
    UsersComponent,
    UrlSecureBlobPipe,
    GetPhotoPerfilComponent,
    UserNewComponent,
    UserEditComponent,
    ReportGeneratorComponent,
    FarmHubsComponent,
    CardHubComponent,
    FarmHubDetailsComponent,
    FarmSensorsComponent,
    CardSensorComponent,
    FarmsCreateComponent,
    CreateOrEditHubComponent,
    CreateOrEditSensorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    ChartsModule,
    GooglePlaceModule,
    ResponsiveModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
