import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PerfilService } from './../../../services/perfil/perfil.service';
import { Perfil } from 'src/app/models/perfil';
declare let $: any;

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit, AfterViewInit {

  perfil: Perfil = {
    id: 0,
    name: '...',
    role: '...',
    farms: 0,
    hubs: 0,
    sensors: 0,
    img_url: '',
  };

  image: any;

  payload: any = {
    role: 'Super Administrador'
  };

  constructor(private perfilService: PerfilService) {
    this.getPerfilLocal();
  }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    $('[data-toggle=tooltip]').tooltip();
  }

  getPerfil() {
    this.perfilService.token();
    this.payload = this.perfilService.payload();
    this.perfilService.getPefil(this.payload.id).subscribe(
      data => {
        this.perfil = data;
        localStorage.setItem('perfil', JSON.stringify(data));
        this.getPhotoLocal(this.perfil.img_url);
      },
      error => {
        console.error(error);
      }
    );
  }

  getPerfilLocal() {
    if (localStorage.getItem('perfil')) {
      this.perfil = JSON.parse(localStorage.getItem('perfil'));
      this.payload.role = this.perfil.role;
      this.getPhotoLocal(this.perfil.img_url);
    } else {
      this.getPerfil();
    }
  }

  getPhoto(id) {
    this.perfilService.getPhoto(id).subscribe(
      (data: any) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.image = reader.result;
        localStorage.setItem('photo', this.image);
        setTimeout(() => {
          $('[data-toggle=tooltip]').tooltip();
        }, 300);
      }, false);
      if (data) {
        reader.readAsDataURL(data);
      }
    },
    error => {
      console.error(error);
    });
  }

  getPhotoLocal(id) {
    if (localStorage.getItem('photo')) {
      this.image = localStorage.getItem('photo');
      setTimeout(() => {
        $('[data-toggle=tooltip]').tooltip();
      }, 300);
    } else {
      this.getPhoto(id);
    }
  }

  toTypeOration(text) {
    const array = text ? text.split(' ') : [];
    let text1 = '';
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      text1 += element.charAt().toUpperCase() + element.slice(1).toLowerCase() + ' ';
    }
    return text1;
  }

}
