import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FarmsService } from 'src/app/services/farms/farms.service';
import { UsersService } from 'src/app/services/users/users.service';
import { PerfilComponent } from '../shared/perfil/perfil.component';
declare let $: any;

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit, AfterViewInit {

  id;
  iduser;

  roles = [];

  formData: any = {
    name: '',
    phone: '',
    role: '',
    address: '',
    birthdate: '',
    email: '',
    password: '',
    farms: [],
    image: null,
  };

  farmsAvailible = [];
  farmsSelected = [];

  message = {
    type: '',
    message: '',
    status: '',
  };

  image: any;

  loadingform = false;

  @ViewChild('componentPefil', { static: true }) perfilComponent: PerfilComponent;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private farmsService: FarmsService, private usersService: UsersService) {
    this.activatedRoute.params.subscribe(
      (params: any) => {
        this.usersService.hasPermissionWithMessage('users.edit');
        if (this.usersService.hasPermission('users.edit')) {
          this.id = params.id;
          this.iduser = parseInt(params.iduser, 10);
          this.getFarms();
        }
      });
  }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    $('.menu-fincas').mCustomScrollbar({
      axis: 'y',
      theme: '3d-thick-dark',
      autoHideScrollbar: false,
      scrollInertia: 300,
      scrollButtons:{ enable: true }
    });
    $('.menu-fincas .mCSB_scrollTools').on('click', (event) => {
      event.stopPropagation();
    });
  }

  getUser() {
    this.usersService.getUser(this.iduser).subscribe(
      (data: any) => {
        this.formData.name = data.name;
        this.formData.phone = data.phone;
        this.formData.address = data.address;
        this.formData.role = data.role.id;
        this.formData.birthdate = data.birthdate;
        this.formData.email = data.email;
        data.farms.forEach((element) => {
          this.addFarm(element.id);
        });
        this.getPhoto(data.image);
      },
      error => {
        console.error(error);
      }
    );
  }

  clearForm() {

    const form: any = document.getElementById('form');
    form.reset();

    const img: any = document.getElementById('preview-image');
    img.src = './assets/img/camera.png';

    this.farmsSelected.forEach((element) => {
      this.removeFarm(element.id);
    });

  }

  goUsers() {
    $('[data-toggle]').tooltip('hide');
    this.router.navigate(['farms', this.id, 'users']);
  }

  getFarms() {
    this.farmsService.getFarmsNoPaginate().subscribe(
      (data: []) => {
        this.farmsAvailible = data;
        this.getRoles();
      },
      error => {
        console.error(error);
      }
    );
  }

  addFarm(id) {
    const farmSelected = this.farmsAvailible.find((element) => element.id === id);
    this.farmsSelected.push(farmSelected);
    this.farmsAvailible = this.farmsAvailible.filter((element) => element.id !== farmSelected.id);
  }

  removeFarm(id) {
    const farmRemoved = this.farmsSelected.find((element) => element.id === id);
    this.farmsAvailible.push(farmRemoved);
    this.farmsSelected = this.farmsSelected.filter((element) => element.id !== farmRemoved.id);
  }

  getFarmsSelected() {
    this.formData.farms = [];
    this.farmsSelected.forEach(element => {
      this.formData.farms.push(element.id);
    });
  }

  submit() {

    this.getFarmsSelected();

    this.loadingform = true;

    this.usersService.updateUser(this.iduser, this.formData).subscribe(
      (data: any) => {
        this.message.type = 'success';
        this.message.message = 'El usuario se ha actualizado exitosamente.';
        this.message.status = data.status;
        this.loadingform = false;
        this.usersService.token();
        const payload = this.usersService.payload();
        if (payload.id === this.iduser) {
          this.perfilComponent.getPerfil();
        }
      },
      (error: any) => {
        console.error(error);
        this.message.type = 'error';
        if (error.error.message === 'Email has been already registered.') {
          this.message.message = 'El E-mail ya ha sido registrado.';
        } else if (error.error.message === 'Notified just must has a farm to notify.') {
          this.message.message = 'El usuario con rol notificado solo puede pertenecer a una finca.';
        } else {
          this.message.message = error.error.message;
        }
        this.message.status = error.error.status;
        this.loadingform = false;
      }
    );

  }

  clearMessage() {
    this.message = {
      type: '',
      message: '',
      status: '',
    };
  }

  updatePhoto() {
    const formData = new FormData();
    formData.append('image', this.formData.image);
    this.usersService.updatePhoto(this.iduser, formData).subscribe(
      (data: any) => {
        this.usersService.token();
        const payload = this.usersService.payload();
        if (payload.id === this.iduser) {
          this.perfilComponent.getPhoto(data.image);
        }
      },
      error => {
        console.error(error);
        this.message.type = 'error';
        if (error.status === 400) {
          this.message.message = 'El formato de la imagen no es soportado. Debe ser jpg, jpeg o png.';
        } else {
          this.message.message = error.error.message;
        }
        this.message.status = error.error.status;
        this.loadingform = false;
      }
    );
  }

  readURL(input) {
    input = document.getElementById(input);
    if (input.files && input.files[0]) {
      const ext = input.files[0].name.split('.')[1];
      if (ext === 'jpg' || ext === 'jpeg' || ext === 'png') {
        this.formData.image = input.files[0];
        this.updatePhoto();
        const reader = new FileReader();
        reader.onload = (e: any) => {
          $('#preview-image').attr('src', e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      } else {
        this.message.type = 'error';
        this.message.message = 'El formato de la imagen no es soportado. Debe ser jpg, jpeg o png.';
        this.message.status = '' + 400;
        input.value = '';
      }
    }
  }

  getRoles() {
    this.usersService.getRoles().subscribe(
      (data: any) => {
        this.roles = data.data;
        this.getUser();
      },
      error => {
        console.error(error);
      }
    );
  }

  getPhoto(id) {
    this.usersService.getPhoto(id).subscribe(
      (data: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          this.image = reader.result;
        }, false);
        if (data) {
          reader.readAsDataURL(data);
        }
      },
      error => {
        console.error(error);
      });
  }
}
