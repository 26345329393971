import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users/users.service';
declare let $: any;

@Component({
  selector: 'app-card-hub',
  templateUrl: './card-hub.component.html',
  styleUrls: ['./card-hub.component.css']
})
export class CardHubComponent implements OnInit {

  @Input() hub;
  @Input() farmId;

  constructor(private router: Router, private userService: UsersService) { }

  ngOnInit() {
    $('[data-toggle=tooltip]').tooltip();
  }

  show(id: number) {
    $('[data-toggle=tooltip]').tooltip('hide');
    this.router.navigate(['/farms', this.farmId, 'hubs', id, 'details']);
  }

  goEdit() {
    $('[data-toggle=tooltip]').tooltip('hide');
    this.router.navigate(['/farms', this.farmId, 'hubs', this.hub.id, 'edit']);
  }

  toGrades(num: number) {
    num = Math.abs(num);
    const grades = parseInt('' + num, 10);
    const minutesTemp = (num - grades) * 60;
    const minutes = parseInt('' + minutesTemp, 10);
    const seconds = parseInt('' + (minutesTemp - minutes) * 60, 10);
    return grades + '°' + minutes + '\'' + seconds + '\'\'';
  }

  hasPermission(permission: string) {
    return this.userService.hasPermission(permission);
  }

}
