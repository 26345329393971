import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotifyService, Type } from '../notify/notify.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseService{

  constructor(private http: HttpClient, private router: Router, private notifyService: NotifyService) {
    super(http);
  }

  public getUsers(page = 1, limit = '') {
    const endpoint = '/users?page=' + page + '&limit=' + limit;
    return this.query(endpoint, 'get').pipe(
      map((info: any) => info)
    );
  }

  public getUsersAdministradores() {
    const endpoint = '/users/admins/farms';
    return this.query(endpoint, 'get');
  }

  public getUser(id) {
    const endpoint = '/users/' + id + '/details';
    return this.query(endpoint, 'get');
  }


  public getPefil(id) {
    const endpoint = '/users/'.concat('' + id).concat('/summary');
    return this.query(endpoint, 'get').pipe(
      map((info: any) => info)
    );
  }

  public getPhoto(id) {
    const endpoint = '/users/' + id;
    return this.query(endpoint, 'get', {}, true);
  }

  public createUser(data) {
    const endpoint = '/users';
    return this.query(endpoint, 'post', data);
  }

  public getRoles() {
    const endpoint = '/roles';
    return this.query(endpoint, 'get');
  }

  public updatePhoto(id, data) {
    const endpoint = '/users/' + id + '/image';
    return this.query(endpoint, 'put', data);
  }

  public updateUser(id, data) {
    const endpoint = '/users/' + id;
    return this.query(endpoint, 'put', data);
  }

  public hasPermission(permission: any): boolean {
    this.token();
    const payload = this.payload();
    const permissions = payload.permissions;
    if (Array.isArray(permission)) {
      let ok = true;
      permission.forEach(value => {
        if (permissions.indexOf(value) === -1) {
          ok = false;
        }
      });
      return ok;
    } else {
      return permissions.indexOf(permission) !== -1;
    }
  }

  hasPermissionWithMessage(permission: any): any {
    if (!this.hasPermission(permission)) {
      this.router.navigate(['/']);
      this.notifyService.notify(Type.danger, 'Error', 'Acceso denegado');
      return false;
    }
    return true;
  }

}
