import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Farm } from 'src/app/models/farm';
import { FarmsService } from 'src/app/services/farms/farms.service';
import { NotifyService, Type } from 'src/app/services/notify/notify.service';
declare let $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, AfterViewInit {

  id: number;
  farms: Array<Farm> = [];
  measurements: any = [];
  textFarmSelected = 'Finca x';
  passed = false;

  icons = [
    { id: 1, name: 'humedad', src: './assets/img/Grupo 472.png' },
    { id: 2, name: 'temperatura', src: './assets/img/Grupo 473.png' },
    { id: 3, name: 'luz', src: './assets/img/Grupo 477.png' },
    { id: 4, name: 'humedad suelo', src: './assets/img/Grupo 476.png' },
    { id: 5, name: 'radiacion', src: './assets/img/icono-radiacion-circle-white.png' },
  ];

  constructor(private activatedRoute: ActivatedRoute, private farmsService: FarmsService, private router: Router, private notifyService: NotifyService) {
    this.activatedRoute.params.subscribe(
      (params: any) => {
        if (params.id) {
          this.id = parseInt(params.id, 10);
          this.showFarmLocal();
          this.getFarms();
        }
      });
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    $('.menu-fincas').mCustomScrollbar({
      axis: 'y',
      theme: '3d-thick-dark',
      autoHideScrollbar: false,
      scrollInertia: 300,
      scrollButtons:{ enable: true }
    });
    $('.menu-fincas .mCSB_scrollTools').on('click', (event) => {
      event.stopPropagation();
    });
  }

  getFarms() {
    this.farmsService.getFarmsNoPaginate().subscribe(
      (data: []) => {
        this.farms = data;
        if (this.measurements.length === 0) {
          this.showFarm(this.id);
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  showFarm(id) {
    // tslint:disable-next-line:triple-equals
    const farm = this.farms.find((element) => element.id == id);
    if (farm) {
      this.farmsService.getStatusFarm(farm.id).subscribe(
        (data: any) => {
          localStorage.setItem('farmStatus', JSON.stringify(data));
          this.textFarmSelected = farm.name;
          this.measurements = data.measurements;
          this.router.navigate([window.location.pathname.replace('' + this.id, '' + farm.id)]);
        },
        error => {
          console.error(error);
        }
      );
      // if (!this.passed) {
      //   this.ngOnInit();
      //   this.passed = true;
      // }
    } else {
      this.notifyService.notify(Type.danger, 'Error', 'Acceso denegado');
      this.router.navigate(['/']);
    }
  }

  showFarmLocal() {
    if (localStorage.getItem('farmStatus')) {
      const farm = JSON.parse(localStorage.getItem('farmStatus'));
      if (this.id == farm.id) {
        this.textFarmSelected = farm.name;
        this.measurements = farm.measurements;
      }
    }
  }

  getIcon(id: number = 1) {
    return this.icons.find((elemento) => elemento.id === id) || this.icons[0];
  }

  logout() {
    $('[data-toggle]').tooltip('hide');
    localStorage.removeItem('token');
    localStorage.removeItem('farmStatus');
    localStorage.removeItem('photo');
    localStorage.removeItem('perfil');
    this.router.navigate(['/']);
  }

}
