import { Injectable } from '@angular/core';
declare var $: any;

export enum Type {
  success = 'success',
  danger = 'danger',
  warning = 'warning',
  info = 'info',
  dark = 'dark'
}

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor() { }

  delay = 5000;

  placement: object = {
    from: 'top',
    align: 'right'
  };

  offset: any = 20;

  public notify(type: Type, title: string, message: string, placement: any = null, delay: number = null,
                buttons: any = null, offset: any = null): any {

    let buttonsHtml = '';

    if (buttons) {
      buttons.buttons.forEach((value) => {
        buttonsHtml += `<button class="${value.class}">${value.text}</button>`;
      });
    }

    const notify = ($ as any).notify({ title, message }, {
      // showProgressbar: true,
      z_index: 2000,
      delay: delay !== null ? delay : this.delay,
      offset: offset !== null ? offset : this.offset,
      placement: placement !== null ? placement : this.placement,
      template:
        `<div data-notify="container" role="alert"
                class="bg-${type} text-light col-xs-12 col-sm-6 col-md-4 col-lg-3 alert alert-${type}">
                <button type="button" class="close" data-notify="dismiss" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <span data-notify="title">
                    <strong>{1}</strong>
                </span>
                <hr class="m-1">
                <span data-notify="message">{2}</span>` +
        (buttons !== null ?
          `<div class="buttonsNotify ${buttons.class}">
                    ${buttonsHtml}
                </div>` : ``) +
        `<div class="progress mt-2" data-notify="progressbar">
                    <div class="progress-bar progress-bar-{0}"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style="background-color: #888">
                    </div>
                </div>
            </div>`
    });

    notify.$ele.find('.buttonsNotify button').each((index, element) => {
      $(element).click(() => {
        buttons.buttons[index].click();
      });
    });

    return notify;

  }

}
