import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare let $: any;

@Component({
  selector: 'app-navbar-minimal',
  templateUrl: './navbar-minimal.component.html',
  styleUrls: ['./navbar-minimal.component.css']
})
export class NavbarMinimalComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  logout() {
    $('[data-toggle]').tooltip('hide');
    localStorage.removeItem('token');
    localStorage.removeItem('farmStatus');
    localStorage.removeItem('photo');
    localStorage.removeItem('perfil');
    this.router.navigate(['/']);
  }

}
