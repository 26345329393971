import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FarmsService } from 'src/app/services/farms/farms.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { UsersService } from 'src/app/services/users/users.service';
declare let $: any;

@Component({
  selector: 'app-create-or-edit-hub',
  templateUrl: './create-or-edit-hub.component.html',
  styleUrls: ['./create-or-edit-hub.component.css']
})
export class CreateOrEditHubComponent implements OnInit, AfterViewInit {

  id: number;
  hubId: number = null;
  title = 'Crear HUB';
  location = '';

  form: any = {
    name: '',
    latitude: null,
    longitude: null,
    farm: ''
  };

  message = {
    type: '',
    message: '',
    status: '',
  };

  farmData: any = {
    id: 0,
    name: '',
    status: 1
  };

  farms = [];
  usersAvailible = [];
  usersSelected = [];
  loadingform = false;
  pressLocation = false;

  markerMap: google.maps.Marker = null;
  gmap: google.maps.Map = null;
  @ViewChild('gmap', { static: false }) gmapElement: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private farmsService: FarmsService, private usersService: UsersService) {
    this.activatedRoute.params.subscribe(
      (params: any) => {
        this.id = params.id;
        if (params.idhub) {
          this.usersService.hasPermissionWithMessage('hubs.edit');
          if (this.usersService.hasPermission('hubs.edit')) {
            this.hubId = params.idhub;
            this.title = 'Editar HUB';
            this.getHub();
          }
        } else {
          this.usersService.hasPermissionWithMessage('hubs.create');
          if (this.usersService.hasPermission('hubs.create')) {
            this.hubId = null;
            this.title = 'Crear HUB';
            this.getFarms();
          }
        }
      });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.hubId === null) {
      this.initMap();
    }
  }

  goListHubs() {
    $('[data-toggle]').tooltip('hide');
    this.router.navigate(['/farms', this.id, 'hubs']);
  }

  getHub() {
    this.farmsService.getHub(this.hubId).subscribe(
      (data: any) => {
        this.form.name = data.name;
        this.form.latitude = data.latitude;
        this.form.longitude = data.longitude;
        this.form.farm = '' + data.farm.id;
        this.farmData.id = data.farm.id;
        this.farmData.name = data.farm.name;
        this.farmData.status = data.farm.status;
        this.initMap();
      },
      error => {
        console.error(error);
      }
    );
  }

  getFarms() {
    this.farmsService.getFarmsNoPaginate().subscribe(
      (data: []) => {
        this.farms = data;
      },
      error => {
        console.error(error);
      }
    );
  }

  public handleAddressChange(address: Address) {
    const lat = address.geometry.location.lat();
    const lng = address.geometry.location.lng();
    this.gmap.setCenter(new google.maps.LatLng(lat, lng));
    this.gmap.setZoom(18);
    this.markerMap.setPosition(new google.maps.LatLng(lat, lng));
    this.form.latitude = lat;
    this.form.longitude = lng;
  }

  initMap() {

    const self = this;
    let position = null;
    let zoom = null;

    if (this.hubId) {
      position = new google.maps.LatLng(self.form.latitude, self.form.longitude);
      zoom = 18;
    } else {
      position = new google.maps.LatLng(4.710988599999999, -74.072092);
      zoom = 5;
    }

    const properties = {
      center: position,
      zoom,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      mapTypeControl: false
    };
    this.gmap = new google.maps.Map(this.gmapElement.nativeElement, properties);
    this.markerMap = new google.maps.Marker({ position, map: this.gmap });

    if (this.hubId) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: position }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results.length > 0) {
            self.location = results[0].formatted_address;
          }
        }
      });
    }

    google.maps.event.addListener(this.gmap, 'place_changed', (event) => {
      // console.log('FROM CHANGED!', event);
    });

    google.maps.event.addListener(this.gmap, 'click', event => {
      self.markerMap.setPosition(new google.maps.LatLng(event.latLng.lat(), event.latLng.lng()));
      self.form.latitude = event.latLng.lat();
      self.form.longitude = event.latLng.lng();
      self.codeLatLng(event.latLng);
    });

  }

  codeLatLng(latlng) {
    const self = this;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({location: latlng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results.length > 0) {
          self.location = results[0].formatted_address;
        }
      }
    });
  }

  submit() {

    this.loadingform = true;

    if (this.hubId === null) {
      this.createHub();
    } else {
      this.updateHub();
    }

  }

  clearMessage() {
    this.message = {
      type: '',
      message: '',
      status: '',
    };
  }

  clearForm() {

    const form: any = document.getElementById('form');
    form.reset();

    this.form = {
      id: null,
      name: '',
      latitude: null,
      longitude: null,
      farm: ''
    };

    this.location = '';
    this.pressLocation = false;

  }

  createHub() {
    this.form.farm = parseInt(this.form.farm, 10);
    this.farmsService.createHub(this.form).subscribe(
      (data: any) => {
        this.message.type = 'success';
        this.message.message = 'El Hub se ha creado exitosamente.';
        this.message.status = data.status;
        this.loadingform = false;
        this.clearForm();
      },
      (error: any) => {
        console.error(error);
        this.message.type = 'error';
        if (error.error.message === 'Farm already has a hub with that name.') {
          this.message.message = 'La finca ya tiene un hub con ese nombre.';
        } else if (error.error.message === 'User has not permission to use this service!') {
          this.message.message = 'El usuario no tiene permiso para usar este servicio';
        } else {
          this.message.message = error.error.message;
        }
        this.message.status = error.error.status;
        this.loadingform = false;
      }
    );
  }

  updateHub() {
    this.farmsService.updateHub(this.hubId, this.form).subscribe(
      (data: any) => {
        this.message.type = 'success';
        this.message.message = 'El Hub se ha actualizado exitosamente.';
        this.message.status = data.status;
        this.loadingform = false;
      },
      (error: any) => {
        console.error(error);
        this.message.type = 'error';
        if (error.error.message === 'Hub with that name already exists on this farm.') {
          this.message.message = 'Hub con ese nombre ya existe en esta finca.';
        } else if (error.error.message === 'User has not permission to use this service!') {
          this.message.message = 'El usuario no tiene permiso para usar este servicio';
        } else {
          this.message.message = error.error.message;
        }
        this.message.status = error.error.status;
        this.loadingform = false;
      }
    );
  }

}
