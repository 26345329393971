import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService } from '../services/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class VerifySesionGuard implements CanActivate {

  constructor(private baseService: BaseService, private router: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let authorized = false;
    if (localStorage.getItem('token')) {
      this.baseService.token();
      const payload: any = this.baseService.payload();
      const now = new Date();
      const exp = new Date(payload.exp * 1000);
      if (now < exp) {
        authorized = true;
      } else {
        localStorage.removeItem('token');
        this.removeLocalStorage();
      }
    }
    if (!authorized) {
      this.removeLocalStorage();
      this.router.navigate(['/']); // this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
    }
    return authorized;
  }

  removeLocalStorage() {
    localStorage.removeItem('farmStatus');
    localStorage.removeItem('photo');
    localStorage.removeItem('perfil');
  }

}
