import { Component, OnInit } from '@angular/core';
import { FarmsService } from 'src/app/services/farms/farms.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sensors-state',
  templateUrl: './sensors-state.component.html',
  styleUrls: ['./sensors-state.component.css']
})
export class SensorsStateComponent implements OnInit {

  id;
  statusSensor;

  optionsHubs = {
    text: 'Todos los HUBs',
    value: ''
  };

  hubs = [];

  constructor(private farmsService: FarmsService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(
      (params: any) => {
        this.id = params.id;
        this.getHubs();
        this.selectAllHubs();
      });
  }

  ngOnInit() {

  }

  selectHub(id) {
    // tslint:disable-next-line:triple-equals
    const hub = this.hubs.find(element => element.id == id);
    if (hub) {
      this.optionsHubs.value = '' + hub.id;
      this.optionsHubs.text = hub.name;
    }
  }

  selectAllHubs() {
    this.farmsService.getStatusSensors(this.id, '').subscribe(
      data => {
        this.statusSensor = data;
        this.optionsHubs.value = '';
        this.optionsHubs.text = 'Todos los HUBs';
      },
      error => {
        console.error(error);
      }
    );
  }

  getHubs() {
    this.farmsService.getHubs(this.id).subscribe(
      data => {
        this.hubs = data.hubs;
      },
      error => {
        console.error(error);
      }
    );
  }

  getStateSensors(id) {
    // tslint:disable-next-line:triple-equals
    const hub = this.hubs.find(element => element.id == id);
    this.farmsService.getStatusSensors(this.id, hub.id).subscribe(
      data => {
        this.statusSensor = data;
        this.optionsHubs.text = hub.name;
        this.optionsHubs.value = hub.id;
      },
      error => {
        console.error(error);
      }
    );
  }

}
